import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { BudgetStatusTranslate } from "@/app/core/util/StatusTranslater";
import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { SelectedServiceUpdate } from "@/app/core/base/controllers/ServicesController";
import CurrencyFormat from "react-currency-format";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { ProcessPrice } from "@/app/core/util/helpers/BudgetHelper";

export default function ServiceItem({
  service,
  refresh,
  clientView,
  isClient,
}) {
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});
  const [userData, setUserData] = useState(Storage.getUserData());

  useEffect(() => {
    if (service && service.id) {
      setData(service);
    }
  }, []);

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function saveOrUpdate() {
    if (edit === true) {
      if (data.priceEdited < data.price) {
        toast.error(Message.LowPrice);
        return;
      }
      save(data);
    }
    setEdit(!edit);
  }

  async function save(infos) {
    await SelectedServiceUpdate(
      infos,
      (res) => {
        toast.success(Message.Saved);
        refresh();
      },
      (error) => console.log("Testando", error),
    );
  }

  return (
    <div className="block block-fx-pop block-bordered border-warning-light border-2x">
      <div className="block-header">
        <div className="block-title">
          <div>
            {edit === false && (userData === null || userData.level === 5)
              ? `${data && data.position}º ${data && data.serviceNameEdited}`
              : ""}
            {edit === false && userData !== null && userData.level !== 5
              ? `${data && data.position}º ${data && data.serviceName}`
              : ""}

            {edit === true && userData.level === 5 ? (
              <input
                className="form-control"
                value={data && data.serviceNameEdited}
                name="serviceNameEdited"
                onChange={(evt) => handleChange(evt)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="block-tools">
          {userData !== null && clientView !== true ? (
            <button
              className="btn btn-lg btn-info"
              onClick={() => saveOrUpdate()}
            >
              {edit === true ? (
                <span>
                  <i className="si si-check" /> Salvar
                </span>
              ) : (
                <span>
                  <i className="fa fa-edit" /> Editar valores
                </span>
              )}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="ml-2 mr-2 mb-2 row">
        <div className="col-12">
          {data && edit === true && userData.level === 5 ? (
            <textarea
              className="form-control"
              defaultValue={
                data && data.descriptionEdited !== undefined
                  ? data.descriptionEdited
                  : `${data.description !== undefined ? data.description : ""}`
              }
              name="descriptionEdited"
              onChange={(evt) => handleChange(evt)}
            />
          ) : (
            `${
              data.descriptionEdited !== undefined
                ? data.descriptionEdited
                : `${data.description !== undefined ? data.description : ""}`
            }`
          )}
        </div>
        <div className="col-12">
          {edit === true && userData.level === 5 ? (
            <div className="form-group">
              <label for="example-masked-time">Valor R$</label>
              <CurrencyFormat
                className={`form-control`}
                thousandSeparator={"."}
                decimalSeparator={","}
                name="marketValue"
                value={data.priceEdited}
                prefix={"R$"}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  handleChange(
                    { target: { name: "priceEdited", value: value } },
                    FieldTypeObj.Currency,
                  );
                }}
              />
            </div>
          ) : (
            ""
          )}

          {edit === false &&
          (userData === null || userData.level === 5) &&
          clientView === true &&
          isClient === false ? (
            <div>{data && ProcessPrice(data)}</div>
          ) : (
            ""
          )}

          {edit === false &&
          (userData === null || userData.level === 5) &&
          clientView === false &&
          isClient === false ? (
            <div>R${MoneyMask(data.priceEdited, 2)}</div>
          ) : (
            ""
          )}
          {edit === false &&
          (userData === null || userData.level === 5) &&
          clientView === true &&
          isClient === true ? (
            <p className="text-success">R${MoneyMask(data.priceEdited, 2)}</p>
          ) : (
            ""
          )}
          {edit === false && userData !== null && userData.level !== 5 ? (
            <p className="text-success">R${MoneyMask(data.priceEdited, 2)}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
