import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function DiscountRulesController() {
  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `discountrules/insert`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }
  
  async function InsertService(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `discountrules/insert/service`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `discountrules/update`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function Get(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `discountrules/`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function GetByDiscount(discountId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `discountrules/service/${discountId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function Delete(serviceGroupId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `discountrules/delete/${serviceGroupId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function DeleteService(serviceId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `discountrules/delete/service/${serviceId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  return {
    Insert,
    InsertService,
    Update,
    Delete,
    GetByDiscount,
    DeleteService,
    Get,
  };
}
