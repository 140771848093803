import React, { useState, useEffect } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import Storage from "@/app/core/util/Storage";
import Grid from "./Grid";
import Productivity from "./Productivity";
import { ToastContainer, toast } from "react-toastify";
import OperationProcessModal from "../../../../core/layouts/main-components/OperationProcessModal";
import BudgetModal from "../../../../core/layouts/main-components/BudgetModal";
import CustomerReports from "./CustomerReports";
import CustomerGrid from "./CustomerGrid";
import { MotivationalList } from "@/app/core/base/controllers/MotivationalController";
import AutomationsController from "@/app/core/base/controllers/AutomationsController";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { ConsultController } from "@/app/core/base/controllers/ConsultController";

export default function MainDashboard() {
  const userData = Storage.getUserData();
  const [loading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [OperationData, setOperationData] = useState({});
  const [motivational, setMotivational] = useState({});
  const enviroment = process.env.REACT_APP_ENV;
  const automations = AutomationsController();
  const organCtrl = OrganListController();

  function toggleModal(data) {
    setSelectedData(data);
    setShowModal(!showModal);
  }

  function toggleOperationModal(data) {
    setOperationData(data);
    setShowOperationModal(!showModal);
  }

  useEffect(() => {
    loadMotivation();
    loadAutomations();
  }, []);

  async function loadAutomations() {
    if (enviroment == "staging" || enviroment == "local") {
       automations.DistributeOrgans(
        (res) => {},
        (err) => {
          console.error("Automatique", err);
        },
      );
       automations.ServicesWithout(
        (res) => {},
        (err) => {
          console.error("Automatique", err);
        },
      );
       automations.CheckContracts(
        (res) => {},
        (err) => {
          console.error("Automatique", err);
        },
      );
       automations.DistributeProcess(
        (res) => {},
        (err) => {
          console.error("Automatique", err);
        },
      );
       automations.AutomaticResend(
        (res) => {},
        (err) => {
          console.error("Automatique", err);
        },
      );
       automations.DistributeService(
        (res) => {},
        (err) => {
          console.error("Automatique", err);
        },
      );
      automations.RepairService(
        (res) => {},
        (err) => {
          console.error("Automatique", err);
        },
      );
      automations.EndProcess(
        (res) => {},
        (err) => {
          console.error("Finalizando processos", err);
        },
      );
      automations.DevolutionResend(
        (res) => {},
        (err) => {
          console.error("Finalizando processos", err);
        },
      );
      automations.AdjustOrgans(
        (res) => {},
        (err) => {
          console.error("Ajustes de orgaos", err);
        },
      );
      automations.CashFlow(
        (res) => {},
        (err) => {
          console.error("Ajustes de orgaos", err);
        },
      );
      automations.EndNotFullfilment(
        (res) => {},
        (err) => {
          console.error("Devolvidos por não cumprimento", err);
        },
      );
       automations.Budgets(
        (res) => {},
        (err) => {
          console.error("Ajustes de orgaos", err);
        },
      );
       organCtrl.Reprocess(
        (res) => {
          console.error("Reprocessado os seguintes órgãos", res);
        },
        (err) => {
          console.error("Reprocessar órgãos", err);
        },
      );
       automations.CustomersLevel(
        (res) => {
          console.error("Reprocessado os clientes", res);
        },
        (err) => {
          console.error("Reprocessar clientes", err);
        },
      );
       automations.Reminders(
        (res) => {
          console.error("Reprocessado os clientes", res);
        },
        (err) => {
          console.error("Reprocessar clientes", err);
        },
      );
       ConsultController.Run(
        (res) => {
          console.error("As automações", res);
        },
        (err) => {
          console.error("Reprocessar clientes", err);
        },
      );
    }
  }

  async function loadMotivation() {
    const baseMotivational = Storage.getMotivational();

    if (baseMotivational == null) {
       MotivationalList((res) => {
        processResult(res);
        Storage.setMotivational(res);
      });
    } else {
      processResult(baseMotivational);
    }
  }

  function processResult(res) {
    let response = res.filter((x) => x.level != 5);
    let result = shuffleArray(shuffleArray(response));
    if (response.length > 0) {
      setMotivational(result[0]);
    }
  }

  function shuffleArray(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  return (
    <>
      <Loading loading={loading} />
      <div className={Storage.getTheme()}>
        {userData && userData.level !== 7 && userData.level !== 10 ? (
          <div>
            {userData && userData.level !== 5 ? (
              <div
                className="bg-image"
                style={
                  motivational && {
                    backgroundImage: `url(${motivational && motivational.url})`,
                  }
                }
              >
                <div className="bg-black-25">
                  <div className="content content-full row">
                    <div className="col-md-7">
                      <div
                        className="py-4 py-md-0 text-white text-center text-md-left js-appear-enabled animated fadeIn"
                        data-toggle="appear"
                      >
                        <h3
                          className="font-size-h4 mb-2 text-white"
                          id="sayhelloHome"
                        >
                          {motivational.message && motivational.message}
                        </h3>
                      </div>
                    </div>
                    {userData.level !== 4 && userData.level !== 6 ? (
                      <div className="col-md-5">
                        <Productivity userData={userData} />
                      </div>
                    ) : (
                      <div style={{ height: 380 }}></div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <CustomerReports
                openProcess={(data) => toggleOperationModal(data)}
              />
            )}
          </div>
        ) : (
          ""
        )}

        <div className="ml-1 mr-1 mb-1 mt-5  row">
          <div className="col-lg-12 col-md-12 col-md-12">
            {userData && userData.level !== 5 ? (
              <Grid
                refresh={refresh}
                setShowModal={(data) => toggleModal(data)}
                setShowOperationModal={(data) => toggleOperationModal(data)}
              />
            ) : (
              <CustomerGrid
                setShowOperationModal={(info) => toggleOperationModal(info)}
              />
            )}
          </div>
        </div>
      </div>
      <BudgetModal
        data={selectedData.data}
        info={selectedData.data}
        show={showModal}
        onHide={() =>
          setShowModal(false) | setRefresh(!refresh) | setSelectedData({})
        }
      />
      <OperationProcessModal
        data={OperationData && OperationData.data}
        show={showOperationModal}
        onHide={() =>
          setShowOperationModal(false) |
          setRefresh(!refresh) |
          setOperationData(null)
        }
      />

      <ToastContainer className="toastr" />
    </>
  );
}
