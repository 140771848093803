import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { Message } from "@/app/core/util/messages/GeralMessages";
import DiscountRulesController from "@/app/core/base/controllers/DiscountRulesController";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { ServiceList } from "@/app/core/base/controllers/ServicesController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Dropdown } from "react-bootstrap";

function DiscountRulesForm(props) {
  const { data, show, onHide, submit, isLoading } = props;

  const [infos, setInfos] = useState({});
  const [services, setServices] = useState([]);

  const discountRulesCtrl = DiscountRulesController();


  useEffect(() => {
    if (data !== null && data.id !== undefined) {
      setInfos(data);
    } else {
      setInfos({ serviceId: 0 });
    }
    loadServices()
  }, [data]);

  async function loadServices() {
    await ServiceList(res => setServices(res));
  }

  async function saveService() {
    if (infos.attribution === 0 && infos.serviceId == 0) {
      toast.error("Se o desconto é atribuído ao serviço, você precisa escolher um serviço para prosseguir");
    } else if (infos.type === 1 && infos.value > 100) {
      toast.error("O desconto não pode ser superior a 100%");
    } else if (infos.id === undefined) {
      await discountRulesCtrl.Insert({ ...infos, serviceId: infos.serviceId == "0" ? null : infos.serviceId }, res => {
        toast.success(Message.Saved);
        onHide();
      })
    } else {
      await discountRulesCtrl.Update({ ...infos, serviceId: infos.serviceId == "0" ? null : infos.serviceId }, res => {
        toast.success(Message.Saved);
        onHide();
      })
    }
  }

  async function addService(service) {
    await discountRulesCtrl.InsertService({ serviceId: service.id, discountId: infos.id }, res => {
      reloadServices()
      toast.success(Message.Saved);
    })
  }

  async function removeService(id) {
    await discountRulesCtrl.DeleteService(id, res => {
      reloadServices()
      toast.success(Message.DeleteSuccess);
    })
  }

  function handleChange(evt, type) {
    setInfos(OnDataChange(infos, evt, type));
  }

  async function reloadServices() {
    await discountRulesCtrl.GetByDiscount(infos.id, res => {
      setInfos({ ...infos, services: res });

    })
  }

  return (
    <Modal
      title="Serviço"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => saveService()}
    >
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label for="example-masked-time">Mínimo</label>
            <input
              type="number"
              className="form-control"
              name="min"
              value={infos.min}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            />
          </div>
          <div className="form-group">
            <label for="example-masked-time">Máximo</label>
            <input
              type="number"
              className="form-control"
              name="max"
              value={infos.max}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            />
          </div>
          <div className="form-group">
            <label for="example-masked-time">Valor</label>
            <input
              type="number"
              className="form-control"
              name="value"
              value={infos.value}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Decimal)}
            />
          </div>
          <div className="form-group">
            <label for="example-masked-time">Tipo de Valor</label>
            <select
              type="number"
              className="form-control"
              name="type"
              value={infos.type}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option>Selecione</option>
              <option value={0}>Preço fixo</option>
              <option value={1}>Porcentagem</option>
            </select>
          </div>
          <div className="form-group">
            <label for="example-masked-time">Atribuir o desconto á:</label>
            <select
              type="number"
              className="form-control"
              name="attribution"
              value={infos.attribution}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option>Selecione</option>
              <option value={0}>Serviços correspondentes</option>
              <option value={1}>Cobrança total do cliente</option>
            </select>
          </div>

          {infos && infos.id !== undefined ? (
            <div className="col-md-12">
              <div className="block block-fx-pop">
                <div className="block-header">
                  <div className="block-title">Serviços vinculados</div>
                  <div className="block-tools">
                    <Dropdown>
                      <Dropdown.Toggle variant="light">Adicionar</Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: 450 }}>
                        <div className="row">
                          {services.map((item) => (
                            <div className="col-6">
                              <div className="block block-bordered block-content row">
                                <div className="col-6">{item.name}</div>
                                <div className="col-6 text-right">
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={() => addService(item)}
                                  >
                                    <i className="si si-plus" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="block-content">
                  {infos && infos.services && infos.services.map((item) => (
                    <div className="block block-rounded block-bordered ribbon ribbon-left ribbon-bookmark ribbon-warning">
                      <div className="block-header block-header-default">
                        <small>
                          {item.service && item.service.name}
                        </small>
                        <div className="block-tools">
                          <button
                            className="btn btn-sm btn-white"
                            onClick={() => removeService(item.id)}
                          >
                            <i className="si si-close text-danger" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
}

export default DiscountRulesForm;
