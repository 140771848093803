import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "@/assets/img/logo.jpg";
import { ReminderController } from "@/app/core/base/controllers/ReminderController";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";
import { ReminderFileController } from "@/app/core/base/controllers/ReminderFileController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import CurrencyFormat from "react-currency-format";
import {
  CPFValidator,
  CivilState,
  CivilStateObj,
  CivilStateTranslate,
  Communion,
  CommunionObj,
  CommunionTranslate,
} from "@/app/core/util/helpers/UserHelper";
import InputMask from "react-input-mask";
import { CpfMask } from "@/app/core/util/helpers/ContactHelper";
import { StateList } from "@/app/core/base/controllers/StateController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import { AdjustCompanyData, GetAddress, GetCompanyByTaxId } from "@/app/core/base/controllers/GlobalController";
import { number } from "yup";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import ModeloConstituicao from "./components/ModeloConstituicao";
import ModeloSLU from "./components/ModeloSLU";
import { YearsAgo } from "@/app/core/util/DateTranslater";
import ModeloDistrato from "./components/ModeloDistrato";

export default function DrafterComponent(props) {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(1);
  const [form, setForm] = useState(0);
  const [logo, setLogo] = useState("");
  const [data, setData] = useState({
    adminitrationType: CustomerHelper().AdministationType.ApenasIsoladamente,
    taxCode: CustomerHelper().TaxCode.ME,
    // name: "Maya Capital",
    // nationality: "Brasileira",
    // birthDate: "1991-12-21",
    // civilState: 0,
    // jobTitle: "Administrador",
    // doc: "123.123.123-12",
    // stateId: "43060160805",
    // street: "Rua Farroupilha",
    // number: "8",
    // neighborhood: "Parque Santo Afonso",
    // postalCode: "06730-000",
    // city: "Vargem Grande Paulista",
    // state: "São Paulo"
  });
  const [partner, setPartner] = useState({});
  const [partners, setPartners] = useState([
  //   {
  //     name: "Fabio Rodrigues",
  //     street: "Rua Farroupilha",
  //     number: "8",
  //     neighborhood: "Parque Santo Afonso",
  //     postalCode: "06730-000",
  //     city: "Vargem Grande Paulista",
  //     state: "São Paulo",
  //     birthDate: "1994-12-21",
  //     nationality: "Brasileira",
  //     civilState: 0,
  //     jobTitle: "43423443",
  //     doc: "123.123.123-12",
  //     stateId: "43060160805",
  //     id: 1,
  //     shares: 50,
  //     value: 0,
  //     valor: 50000,
  //     adm: true
  // },
  // {
  //     name: "Maya Capital",
  //     nationality: "Brasileira",
  //     birthDate: "1991-12-21",
  //     civilState: 0,
  //     jobTitle: "Administrador",
  //     doc: "1923789127389127",
  //     stateId: "43060160805",
  //     street: "Rua Farroupilha",
  //     number: "8",
  //     neighborhood: "Parque Santo Afonso",
  //     postalCode: "06730-000",
  //     city: "Vargem Grande Paulista",
  //     state: "São Paulo",
  //     id: 2,
  //     shares: 50,
  //     value: 0,
  //     valor: 50000,
  //     adm: true
  // }
  ]);
  const [loading, setLoading] = useState(false);
  const [newPartner, setNewPartner] = useState(false);
  const [userData, setUserData] = useState(Storage.getUserData());
  const [states, setStates] = useState([]);
  const inputFile = useRef(null);
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  function onUploadClick() {
    inputFile.current.click();
  }

  useEffect(() => { }, [step]);

  useEffect(() => {
    setForm(props.match.params.key);
    setLogo(Logo);
    loadStates();
  }, []);

  function processAdministration(list) {
    const adms = list.filter(x => x.adm === true);
    let base = data;
    if (adms.length == 1) {
      base.adminitrationType = CustomerHelper().AdministationType.ApenasIsoladamente;
    }
    if (adms.length > 1) {
      base.adminitrationType = CustomerHelper().AdministationType.IsoladamenteEEmConjunto;
    }

    console.log("Dados", base);
    setData(base);
    notifyChange();
  }

  function loadStates() {
    StateList((res) => {
      setStates(res);
    });
  }

  function notifyChange() {
    setChange(date.getTime());
  }

  function loadReminder() { }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
    if (evt.target.name == "postalCode" && evt.target.value.length >= 8) {
      GetAddress(evt.target.value, (res) => {
        setData({ ...data, ...res, postalCode: evt.target.value });
      });
    }
    if (evt.target.name == "taxId" && evt.target.value.length >= 14) {
      GetCompanyByTaxId(evt.target.value, (res) => {
        let base = AdjustCompanyData(res);
        console.log("Dados", base);
        setData({ ...data, ...res, taxId: evt.target.value, ...base });
      }, err => {
        setData({ ...data, taxId: evt.target.value });
      });
    }
  }

  function handleAdmChange(evt, type) {
    console.log("Dados", evt, OnDataChange(partner, evt, type));
    setPartner(OnDataChange(partner, evt, type));
  }

  function toSecond() {
    let error = false;
    let msg = "";
    if (!data.name) {
      error = true;
      msg += "Nome da empresa; ";
    }

    if (!data.street) {
      error = true;
      msg += "Logradouro; ";
    }

    if (!data.neighborhood) {
      error = true;
      msg += "Bairro; ";
    }

    if (!data.postalCode) {
      error = true;
      msg += "CEP; ";
    }

    if (!data.city) {
      error = true;
      msg += "Cidade; ";
    }

    if (!data.state) {
      error = true;
      msg += "Estado; ";
    }

    if (!data.activity) {
      error = true;
      msg += "Atividade; ";
    }
    if (!data.capital) {
      error = true;
      msg += "Capital social; ";
    }

    if (error) {
      toast.error(`Faltou preenche o ${msg}`);
    } else {
      setStep(step + 1);
    }
  }

  function addPartner(adm) {
    let error = false;
    let msg = "Faltou preenche o ";
    if (!partner.name) {
      error = true;
      msg += "Nome; ";
    }
    if (!partner.nationality) {
      error = true;
      msg += "Nacionalidade; ";
    }
    if (partner.civilState == undefined) {
      error = true;
      msg += "Estado civil; ";
    }
    if (!partner.jobTitle) {
      error = true;
      msg += "Profissão; ";
    }
    if (!partner.doc) {
      error = true;
      msg += "RG; ";
    }
    if (!partner.street) {
      error = true;
      msg += "Logradouro; ";
    }

    if (!partner.neighborhood) {
      error = true;
      msg += "Bairro; ";
    }

    if (!partner.postalCode) {
      error = true;
      msg += "CEP; ";
    }

    if (!partner.city) {
      error = true;
      msg += "Cidade; ";
    }

    if (!partner.state) {
      error = true;
      msg += "Estado; ";
    }


    if (!partner.state) {
      error = true;
      msg += "Estado; ";
    }

    // if (!partner.shares) {
    //   error = true;
    //   msg += "Participação; ";
    // }
    if (!partner.stateId) {
      error = true;
      msg += "CPF; ";
    } else {
      if (!CPFValidator(partner.stateId)) {
        msg = "O CPF é inválido";
        error = true;
      }
    }

    if (!partner.birthDate) {
      error = true;
      msg += "Data de nascimento; ";
    } else {
      const time = YearsAgo(partner.birthDate)
      if (time > 100) {
        error = true;
        msg = "A data de nascimento não pode ser superior a 100 anos atrás";
      }
    }


    if (error) {
      toast.error(msg);
    } else {
      let base = { ...partner, id: partners.length + 1, shares: 0, value: 0 }
      if (data.societyType !== CustomerHelper().SocietyType.SociedadeUnipessoal) {
        base.shares = data.capital
        base.valor = data.capital;
      }
      if (adm) {
        base.adm = true;
        base.onlyAdm = true;
        setData({ ...data, newAdm: false })
      } else {
        base.adm = true;
      }
      setPartners([...partners, base]);
      console.log("Parceiros", [...partners, base]);
      setPartner({});
      setNewPartner(false);
    }
  }

  function partnerForm(index, adm) {
    return (
      <div className="m-2 mt-3 row">
        <div className="form-group col-md-6">
          <span>Nome:</span>
          <input
            className="form-control"
            value={partner && partner.name}
            key="namePartner"
            name="name"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>Nacionalidade:</span>
          <input
            className="form-control"
            value={partner && partner.nationality}
            key="nationalityPartner"
            name="nationality"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>Data de Nascimento:</span>
          <input
            className="form-control"
            type="date"
            value={partner && partner.birthDate}
            max={new Date().toJSON().split('T')[0]}
            key="birthDate"
            name="birthDate"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>
            Estado Civil:
          </span>
          <select
            className="form-control"
            value={partner && partner.civilState}
            key="civilStatePartner"
            name="civilState"
            onChange={(evt) => handleAdmChange(evt, FieldTypeObj.Number)}
          >
            <option value={null}>Selecione</option>
            {CivilState.map((item) => (
              <option value={item.value}>{item.name}</option>
            ))}
          </select>
        </div>
        {partner && partner.civilState == CivilStateObj.Married ? (
          <div className="form-group col-md-6">
            <span>Tipo de comunhão</span>
            <select
              className="form-control"
              value={partner && partner.communion}
              key="communionPartner"
              name="communion"
              onChange={(evt) => handleAdmChange(evt, FieldTypeObj.Number)}
            >
              <option value={null}>Selecione</option>
              {Communion.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
        ) : (
          ""
        )}
        <div className="form-group col-md-6">
          <span>Profissão:</span>
          <input
            className="form-control"
            value={partner && partner.jobTitle}
            key="jobTitlePartner"
            name="jobTitle"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>RG ou RNE:</span>
          <input
            className="form-control"
            value={partner && partner.doc}
            key="docPartner"
            name="doc"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>CPF:</span>
          <InputMask
            mask="999.999.999-99"
            maskChar=""
            type="text"
            className="form-control"
            id="advanced-filter-document"
            name="cpf"
            placeholder="999.999.999-99"
            value={partner && partner.stateId}
            onChange={(event) => {
              handleAdmChange({
                target: {
                  name: "stateId",
                  value: event.target.value.replace(/\D/g, ""),
                },
              });
            }}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Logradouro:</span>
          <input
            className="form-control"
            value={partner && partner.street}
            key="streetPartner"
            name="street"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <div className="row">
            <div className="col-6">
              <label for="address">Número</label>
            </div>
            <div className="col-6 text-right">
              <button className="btn btn-sm btn-dual" onClick={() => setPartner({
                ...partner,
                noNumber: partner.noNumber == false || partner.noNumber == undefined ? true : false,
                number: partner.noNumber == false || partner.noNumber == undefined ? "S/N" : ""
              })}>
                {partner && partner.noNumber ?
                  <i className="si si-close mr-1" />
                  :
                  <i className="si si-check mr-1" />
                }
                Sem número</button>
            </div>
          </div>
          <input
            placeholder="Número"
            className="form-control"
            type="text"
            disabled={partner && partner.noNumber}
            name="number"
            value={partner && partner.number}
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Bairro:</span>
          <input
            className="form-control"
            value={partner && partner.neighborhood}
            key="neighborhoodPartner"
            name="neighborhood"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Complemento:</span>
          <input
            className="form-control"
            value={partner && partner.complement}
            key="complementPartner"
            name="complement"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>CEP:</span>
          <input
            className="form-control"
            value={partner && partner.postalCode}
            key="postalCodePartner"
            name="postalCode"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Município:</span>
          <input
            className="form-control"
            value={partner && partner.city}
            key="cityPartner"
            name="city"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Estado:</span>
          <select
            className="form-control"
            value={partner && partner.state}
            key="statePartner"
            name="state"
            onChange={(evt) => handleAdmChange(evt)}
          >
            <option value={null}>Selecione</option>
            {states &&
              states.map((item) => (
                <option value={item.longName}>{item.longName}</option>
              ))}
          </select>
        </div>

        {/* <div className="form-group col-md-6">
          <span>Porcentagem do capital</span>
          <input
            className="form-control"
            type="number"
            value={partner && partner.shares}
            key="sharesPartner"
            name="shares"
            onChange={(evt) => handleAdmChange(evt, FieldTypeObj.Number)}
          />
        </div> */}
        {/* Se as cotas não corresponderem a 100% da empresa, devemos evitar a pessoa de avançar */}

        {index != undefined ? (
          ""
        ) : (
          <div className="text-center col-md-12">
            <button
              className="btn btn-warning btn-square mt-4 mb-4"
              onClick={() => addPartner(adm)}
            >
              Salvar {adm ? "Administrador" : "sócio"}
            </button>
          </div>
        )}

        {/* Depois de salvar apresentar como conteiner e colocar o botão Adicionar Sócio */}

        {loading === true ? (
          <div className="text-center">
            <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  function editItem(index) {
    let old = partners;
    old[index].edit =
      old[index].edit == undefined || old[index].edit == false ? true : false;
    setPartners(old);
    setPartner(old[index]);
    console.log("Sócio editado", old[index])
    notifyChange();
  }

  function saveItem(index) {
    let error = false;
    console.log("Partiner editando", partner);
    let msg = "Faltou preenche o ";
    if (!partner.name) {
      error = true;
      msg += "Nome; ";
    }
    if (!partner.nationality) {
      error = true;
      msg += "Nacionalidade; ";
    }
    if (partner.civilState == undefined) {
      error = true;
      msg += "Estado civil; ";
    }
    if (!partner.jobTitle) {
      error = true;
      msg += "Profissão; ";
    }
    if (!partner.doc) {
      error = true;
      msg += "RG; ";
    }
    if (!partner.stateId) {
      error = true;
      msg += "CPF; ";
    } else {
      if (!CPFValidator(partner.stateId)) {
        msg = "O CPF é inválido";
        error = true;
      }
    }

    if (error) {
      toast.error(msg);
    } else {
      let old = partners;
      old[index] = partner;
      old[index].edit = false;
      setPartners(old);
      console.log("Parceiros", old);
      setPartner({});
      notifyChange();
    }
  }

  function removeItem(index) {
    if (window.confirm(Message.DeleteConfirm)) {
      let old = partners;
      delete old[index];
      setPartners(old);
      notifyChange();
    }
  }


  function checkAdministrator(evt, index) {
    let old = partners;
    old[index].adm = evt.target.checked;
    setPartners(old);
    processAdministration(old)
    notifyChange();
  }

  function checkProlabore(evt, index) {
    let old = partners;
    old[index].prolabore = evt.target.checked;
    setPartners(old);
    notifyChange();
  }

  function checkWage(index) {
    let old = partners;
    old[index].wage = !old[index].wage;
    setPartners(old);
    notifyChange();
  }

  function addressFields() {
    return (
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label for="address">Logradouro</label>
            <input
              placeholder="Logradouro"
              className="form-control"
              type="text"
              name="street"
              value={data && data.street}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <div className="row">
              <div className="col-6">
                <label for="address">Número</label>
              </div>
              <div className="col-6 text-right">
                <button className="btn btn-sm btn-dual" onClick={() => setData({
                  ...data,
                  noNumber: data.noNumber == false || data.noNumber == undefined ? true : false,
                  number: data.noNumber == false || data.noNumber == undefined ? "S/N" : ""
                })}>
                  {data && data.noNumber ?
                    <i className="si si-close mr-1" />
                    :
                    <i className="si si-check mr-1" />
                  }
                  Sem número</button>
              </div>
            </div>
            <input
              placeholder="Número"
              className="form-control"
              type="text"
              disabled={data && data.noNumber}
              name="number"
              value={data && data.number}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label for="address">Bairro</label>
            <input
              placeholder="Bairro"
              className="form-control"
              type="text"
              name="neighborhood"
              value={data && data.neighborhood}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label for="address">Complemento</label>
            <input
              placeholder="Complemento"
              className="form-control"
              type="text"
              name="complement"
              value={data && data.complement}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label for="city">Cidade</label>
            <input
              placeholder="Cidade"
              className="form-control"
              type="text"
              name="city"
              value={data && data.city}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label for="uf">Estado</label>
            <select
              name="uf"
              onChange={(evt) => handleChange(evt)}
              value={data.uf}
              className="form-control"
            >
              <option value={null}>Selecionar</option>
              {states &&
                states.map((state) => (
                  <option
                    value={state.shortName}
                    selected={data.uf === state.shortName}
                  >
                    {state.longName} ({state.shortName})
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    )
  }

  function goSecond() {
    if (data.societyType == undefined) {
      toast.error(Message.PendingData);
    } else {
      setStep(step + 1);
      setProgress(progress + 10)
    }
  }

  function goThird() {
    if (!data.companyName) {
      toast.error(Message.PendingData);
    } else {
      setStep(step + 1);
      setProgress(progress + 10)
    }
  }

  function goToFourth() {
    if (partners.length == 0) {
      toast.error(Message.PendingData);
    } else {
      setProgress(progress + 10);
      setStep(step + 1);
    }
  }

  function handleShares(evt, index) {
    let old = partners;
    old[index].shares = parseInt(evt.target.value);

    old[index].valor = (old[index].shares / 100) * data.capital;
    console.log("valores", old[index].valor, data.capital, old[index].shares)

    setPartners(old);
    notifyChange();
  }

  function goFifth() {
    if (data.street == undefined) {
      toast.error(`Faltou preencher a rua`);
    } else if (data.number == undefined) {
      toast.error(`Faltou preencher o número`);
    } else if (data.neighborhood == undefined) {
      toast.error(`Faltou preencher o bairro`);
    } else if (data.city == undefined) {
      toast.error(`Faltou preencher a cidade`);
    } else if (data.uf == undefined) {
      toast.error(`Faltou preencher o estado`);
    } else {
      setProgress(progress + 10);
      setStep(step + 1);
    }
  }

  function goSix() {
    if (data.description == undefined) {
      toast.error(`Preencha a descrição para continuar`);
    } else {
      setProgress(progress + 10);
      setStep(step + 1);
    }
  }

  function goSeven() {
    if (data.capital == undefined) {
      toast.error(`Preencha o capital social para continuar`);
    } else {
      if (data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal) {
        setProgress(progress + 30);
        setStep(step + 2);
      } else {
        setProgress(progress + 10);
        setStep(step + 1);
      }

    }
  }

  function goEight() {
    let shares = 0;

    for (let s of partners) {
      console.log(s, shares, partners)
      if (s) {
        shares += s.shares;
      }
    }
    console.log("parceiros", partners, shares)
    if (shares < 100) {
      toast.error(
        "A quantidade de quotas está abaixo de 100% do capital social",
      );
    } else if (shares > 100) {
      toast.error(
        "A quantidade de quotas está acima de 100% do capital social",
      );
    } else {
      setProgress(progress + 10);
      setStep(step + 1);
    }
  }

  function goNine() {
    let shares = 0;

    for (let s of partners) {
      console.log(s, shares, partners)
      if (s && s.adm) {
        shares++;
      }
    }
    console.log("parceiros", partners, shares)
    if (shares == 0) {
      toast.error(
        "Ao menos 1 dos sócios precisa ser administrador",
      );
    } else {
      setProgress(progress + 10);
      setStep(step + 1);
    }
  }

  function goFinish() {
    setProgress(progress + 10);
    setStep(step + 1);
  }

  function goBack() {
    if (step > 1) {
      setStep(step - 1)
      setProgress(progress - 10);
    }
  }

  function selectDistractResponsible(item) {
    let users = partners;
    for (let s of users) {
      if (s.stateId == item.stateId) {
        s.responsible = true;
      } else {
        s.responsible = false;
      }
    }

    console.log("Mudança de conta", users)
    setPartners(users);
  }

  function changeOpenStep() {
    switch (step) {
      case 1:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Vamos começar do começo... Qual tipo de empresa deseja abrir?</h4><br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="companyType1" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "societyType",
                      value: evt.target.checked == true ? CustomerHelper().SocietyType.SociedadeLimitada : 0
                    }
                  })} />
                  <label class="custom-control-label" for="companyType1">Sociedade Limitada (LTDA)</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="companyType2" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "societyType",
                      value: evt.target.checked == true ? CustomerHelper().SocietyType.SociedadeUnipessoal : 0
                    }
                  })} />
                  <label class="custom-control-label" for="companyType2">Sociedade Unipessoal (SLU)</label>
                </div>
                {/* <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="companyType3" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "societyType",
                      value: evt.target.checked == true ? CustomerHelper().SocietyType.EmpresarioIndividual : 0
                    }
                  })} />
                  <label class="custom-control-label" for="companyType3">Empresário Individual (EI)</label>
                </div> */}
              </div>
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => goSecond()}>Próximo</button>
          </div>
        )
      case 2:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Legal... Agora digite o nome completo dessa empresa! (Não é necessário digitar LTDA)</h4>
                <input className="form-control" name="companyName" value={data && data.companyName} onChange={evt => handleChange(evt)} />
              </div>

              <div className="form-group pb-3">
                <span>Essa empresa terá nome fantasia?</span>
                <br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="adminitration1" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "hasFantasyName",
                      value: evt.target.checked == true ? true : false
                    }
                  })} />
                  <label class="custom-control-label" for="adminitration1">Sim</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="adminitration2" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "hasFantasyName",
                      value: evt.target.checked == true ? false : true
                    }
                  })} />
                  <label class="custom-control-label" for="adminitration2">Não</label>
                </div>
              </div>

              {data && data.hasFantasyName == true ?
                <div className="form-group">
                  <span className="text-center">Digite abaixo o nome fantasia desejado</span>
                  <input className="form-control" name="fantasyName" value={data && data.fantasyName} onChange={evt => handleChange(evt)} />
                </div>
                : ""}

              <button className="btn btn-block btn-warning" onClick={() => goThird()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        )
      case 3:
        return (
          <div className="block-content">
            <h4 className="text-center">Ok, agora me informe quem {data && data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? "será o sócio" : "serão os sócios"}:</h4>
            {change && partners && partners.length == 0 ? (
              partnerForm()
            ) : (
              <p></p>
            )}
            {change &&
              partners &&
              partners.map((item, index) => {
                if (item && !item.onlyAdm) {
                  return (
                    <div>
                      <div
                        className="block block-bordered border-2x border-warning"
                        key={`partiner-${index}`}
                      >
                        <div className="block-header">
                          <div className="block-title">Sócio: {item && item.name}</div>
                          <div className="block-tools">
                            {change && item && item.edit != true ? (
                              <button
                                className="btn btn-dual"
                                title="Editar Sócio"
                                onClick={() => editItem(index)}
                              >
                                <i className="far fa-edit" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-success"
                                title="Salvar Sócio"
                                onClick={() => saveItem(index)}
                              >
                                <i className="si si-success" /> Salvar
                              </button>
                            )}
                            <button
                              className="btn btn-dual"
                              onClick={() => removeItem(index)}
                            >
                              <i className="si si-close text-danger" />
                            </button>
                          </div>
                        </div>
                        {item && item.edit != true ? (
                          <div className="block-content row">
                            <div className="col-md-6">
                              <b className="font-w700">Nome</b>
                              <br />
                              <p>{item.name}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Nacionalidade</b>
                              <br />
                              <p>{item.nationality}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Estado civil</b>
                              <br />
                              <p>{CivilStateTranslate(item.civilState)}</p>
                            </div>
                            {item.civilState == CivilStateObj.Married ? (
                              <div className="col-md-6">
                                <b className="font-w700">Tipo de comunhão</b>
                                <br />
                                <p>{CommunionTranslate(item.communion)}</p>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-md-6">
                              <b className="font-w700">Profissão</b>
                              <br />
                              <p>{item.jobTitle}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">RG</b>
                              <br />
                              <p>{item.doc}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">CPF</b>
                              <br />
                              <p>{CpfMask(item.stateId)}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Logradouro</b>
                              <br />
                              <p>{item.street}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Numero</b>
                              <br />
                              <p>{item.number ? item.number : "S/N"}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Bairro</b>
                              <br />
                              <p>{item.neighborhood}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Complemento</b>
                              <br />
                              <p>{item.complement}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">CEP</b>
                              <br />
                              <p>{item.postalCode}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Cidade</b>
                              <br />
                              <p>{item.city}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Estado</b>
                              <br />
                              <p>{item.state}</p>
                            </div>
                            {/* <div className="col-md-6">
                          <b className="font-w700">Porcentagem do capital</b>
                          <br />
                          <p>{item.shares}</p>
                        </div> */}
                          </div>
                        ) : (
                          partnerForm(index)
                        )}
                      </div>
                      <hr />
                    </div>
                  )
                }
              })}
            {newPartner == false && partners.length > 0 && data && data.societyType !== CustomerHelper().SocietyType.SociedadeUnipessoal ? (
              <div className="text-center">
                <button
                  className="btn btn-warning btn-square mt-4 mb-4"
                  key={"newPartnet"}
                  onClick={() => setNewPartner(!newPartner)}
                >
                  Adicionar Sócio
                </button>
              </div>
            ) : newPartner == true && partners.length > 0 ? (
              partnerForm()
            ) : (
              ""
            )}

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => goToFourth()}
              >
                Próximo
              </button>
            </div>
          </div>
        );

      case 4:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Bacana, me confirme o endereço onde a empresa irá se instalar?</h4>
              <div className="form-group">
                <span>CEP</span>
                <input className="form-control" name="postalCode" placeholder="CEP" value={data && data.postalCode} onChange={evt => handleChange(evt)} />
              </div>
              {data && data.postalCode && data.postalCode.length >= 8 ?
                addressFields()
                : ""}

              <button className="btn btn-block btn-warning" onClick={() => goFifth()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      case 5:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Entendi, vamos falar sobre a atividade desta empresa agora.</h4>
              <div className="form-group">
                <span>Me informe (detalhadamente) qual será o objeto social?</span>
                <textarea className="form-control" name="description" placeholder="" value={data && data.description} onChange={evt => handleChange(evt)} />
              </div>


              <button className="btn btn-block btn-warning" onClick={() => goSix()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      case 6:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Show! Então vamos falar de capital social, quanto será investido em capital?</h4>
              <div className="form-group">
                <span>Escreva o valor em reais</span>
                <CurrencyFormat
                  className={`form-control`}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  name="marketValue"
                  prefix={"R$"}
                  value={data && data.capital}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    handleChange(
                      { target: { name: "capital", value: value } },
                      FieldTypeObj.Currency,
                    );
                  }}
                />
              </div>
              <div className="form-group">
                <span>Para confirmar escreva o valor acima por extenso</span>
                <input className="form-control" name="capitalExtended" placeholder="Exemplo: dez mil" value={data && data.capitalExtended} onChange={evt => handleChange(evt)} />
              </div>

              <div className="form-group pb-3">
                <span>Qual será o porte da empresa?</span>
                <br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="taxCode1" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "taxCode",
                      value: evt.target.checked === true ? CustomerHelper().TaxCode.ME : 0
                    }
                  })} checked={data && data.taxCode === CustomerHelper().TaxCode.ME ? true : false }/>
                  <label class="custom-control-label" for="taxCode1">ME</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="taxCode2" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "taxCode",
                      value: evt.target.checked === true ? CustomerHelper().TaxCode.EPP : 0
                    }
                  })} checked={data && data.taxCode === CustomerHelper().TaxCode.EPP ? true : false }/>
                  <label class="custom-control-label" for="taxCode2">EPP</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="taxCode3" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "taxCode",
                      value: evt.target.checked === true ? CustomerHelper().TaxCode.Normal : 0
                    }
                  })} checked={data && data.taxCode === CustomerHelper().TaxCode.Normal ? true : false }/>
                  <label class="custom-control-label" for="taxCode3">Normal</label>
                </div>
              </div>

              <button className="btn btn-block btn-warning" onClick={() => goSeven()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      case 7:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center m-0">Ok, e como ficará a distribuição entre os sócios?</h4>
              <p className="text-center ">Capital social registrado: R${data && data.capital && MoneyMask(data.capital, 2)}</p>

              {change && partners && partners.map((item, index) => {
                if (!item.onlyAdm) {
                  return (
                    <div className="block block-rounded block-bordered">
                      <div className="block-header">
                        <div className="block-title">
                          <div class="ml-3 text-left">
                            <p class="font-w600 mb-0">{item && item.name}</p>
                            <p class="font-size-sm font-italic text-muted mb-0">
                              {item && item.stateId && CpfMask(item.stateId)}
                            </p>
                          </div>
                        </div>
                        <div className="block-tools">
                          <div className="form-group">
                            <span>Digite a %</span>
                            <input type="number" className="form-control" min={1} max={100} value={item && item.shares} onChange={evt => handleShares(evt, index)} />
                          </div>
                          <span>
                            R${item && item.valor ? MoneyMask(item.valor, 2) : "00,00"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                }
              }
              )}


              <button className="btn btn-block btn-warning" onClick={() => goEight()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      case 8:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Falta pouco! Me diga, quem será o administrador da empresa?</h4>

              {change && partners && partners.map((item, index) => (
                <div className="block block-rounded block-bordered">
                  <div className="block-header">
                    <div className="block-title d-flex">
                      {item && item.onlyAdm ?
                        <button className="btn btn-dual text-danger" title="Remover administrador">
                          <i className="si si-close" />
                        </button>
                        :
                        ""
                      }
                      <div class="ml-3 text-left">
                        <p class="font-w600 mb-0">{item && item.name}{item && item.onlyAdm ? <small> - Administrador não sócio</small>
                          : ""
                        }
                        </p>
                        <p class="font-size-sm font-italic text-muted mb-0">
                          {item && item.stateId && CpfMask(item.stateId)}
                        </p>
                      </div>
                    </div>
                    <div className="block-tools">
                      <div className="custom-control custom-checkbox custom-control-warning mb-1">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={item && item.adm}
                          id={index}
                          onChange={(evt) => checkAdministrator(evt, index)}
                        />
                        <label className="custom-control-label" for={index}>
                          Selecionar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {data && data.newAdm ?
                <div className="border border-rounded border-2x border-warning">
                  {partnerForm(undefined, true)}
                </div>
                : ""}

              <div className="text-center py-3 mb-2">
                <button className="btn btn-success" onClick={() => setData({ ...data, newAdm: data.newAdm == false || data.newAdm == undefined ? true : false })}>{data.newAdm == false || data.newAdm == undefined ? "Adicionar administrador não sócio" : "Remover formulário"}</button>
              </div>

              <div className="form-group pb-3">
                <span>Como será feita o uso da administração?</span>
                <br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="adminitration1" name="example-rd-custom-inline"
                    checked={data && data.adminitrationType == CustomerHelper().AdministationType.IsoladamenteEEmConjunto ? true : false}
                    onChange={evt => handleChange({
                      target: {
                        name: "adminitrationType",
                        value: evt.target.checked == true ? CustomerHelper().AdministationType.IsoladamenteEEmConjunto : 0
                      }
                    })} />
                  <label class="custom-control-label" for="adminitration1">Isoladamente e em conjunto</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="adminitration2" name="example-rd-custom-inline"
                    checked={data && data.adminitrationType == CustomerHelper().AdministationType.ApenasIsoladamente ? true : false}
                    onChange={evt => handleChange({
                      target: {
                        name: "adminitrationType",
                        value: evt.target.checked == true ? CustomerHelper().AdministationType.ApenasIsoladamente : 0
                      }
                    })} />
                  <label class="custom-control-label" for="adminitration2">Apenas isoladamente</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="adminitration3" name="example-rd-custom-inline"
                    checked={data && data.adminitrationType == CustomerHelper().AdministationType.ApenasEmConjunto ? true : false}
                    onChange={evt => handleChange({
                      target: {
                        name: "adminitrationType",
                        value: evt.target.checked == true ? CustomerHelper().AdministationType.ApenasEmConjunto : 0
                      }
                    })} />
                  <label class="custom-control-label" for="adminitration3">Apenas em conjunto</label>
                </div>
              </div>

              <button className="btn btn-block btn-warning" onClick={() => goNine()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      case 9:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">{data.societyType !== CustomerHelper().SocietyType.SociedadeUnipessoal ? "Algum deles" : "O sócio"} terá retirada de pró-labore?</h4>

              {change && partners && partners.map((item, index) => {
                if (!item.onlyAdm) {
                  return (
                    <div className="block block-rounded block-bordered">
                      <div className="block-header">
                        <div className="block-title">
                          <div class="ml-3 text-left">
                            <p class="font-w600 mb-0">{item && item.name}</p>
                            <p class="font-size-sm font-italic text-muted mb-0">
                              {item && item.stateId && CpfMask(item.stateId)}
                            </p>
                          </div>
                        </div>
                        <div className="block-tools">
                          <div className="custom-control custom-checkbox custom-control-warning mb-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={item.prolabore}
                              id={index}
                              onChange={(evt) => checkProlabore(evt, index)}
                            />
                            <label className="custom-control-label" for={index}>
                              Selecionar
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}


              <button className="btn btn-block btn-warning" onClick={() => goFinish()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      default:
        return (
          <div className="content">
            <div className="block block-fx-pop block-bordered border-success mt-5">
              <div className="block-header">
                <div className="block-content text-center">
                  <i className="far fa-check-circle text-success mr-2" />{" "}
                  Pronto, seu contrato já está elaborado!
                  <br />
                  <button className="btn btn-square btn-info mt-3" onClick={() => window.location = "#/elaborator"}>
                    Clique aqui para baixar o arquivo em Word
                  </button>
                </div>
              </div>
            </div>
            <ModeloConstituicao data={data} partners={partners} />
            {/* {data && data.societyType == CustomerHelper().SocietyType.SociedadeLimitada ?
              : data && data.societyType == CustomerHelper().SocietyType.SociedadeUnipessoal ?
                <ModeloSLU data={{ ...data, partners }} /> : ""
            } */}

            <button className="btn btn-block btn-warning" onClick={() => goNine()}>Próximo</button>
            <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
          </div>
        );
    }
  }




  /* Cancelamento */

  function changeCancelation() {
    switch (step) {
      case 1:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Vamos começar do começo... Qual tipo de empresa deseja cancelar?</h4><br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="companyType1" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "societyType",
                      value: evt.target.checked == true ? CustomerHelper().SocietyType.SociedadeLimitada : 0
                    }
                  })} />
                  <label class="custom-control-label" for="companyType1">Sociedade Limitada (LTDA)</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="companyType2" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "societyType",
                      value: evt.target.checked == true ? CustomerHelper().SocietyType.SociedadeUnipessoal : 0
                    }
                  })} />
                  <label class="custom-control-label" for="companyType2">Sociedade Unipessoal (SLU)</label>
                </div>

              </div>
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => goSecond()}>Próximo</button>
          </div>
        )
      case 2:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Entendi, então vamos iniciar o processo de baixa/distrato. Qual o CNPJ da empresa?</h4><br />
                <InputMask
                  mask="99.999.999/9999-99"
                  maskChar=""
                  type="text"
                  className="form-control"
                  id="advanced-filter-document"
                  name="taxId"
                  placeholder="99.999.999/9999-99"
                  value={data && data.taxId}
                  onChange={(event) => {
                    handleChange({
                      target: {
                        name: "taxId",
                        value: event.target.value.replace(/\D/g, ""),
                      },
                    });
                  }}
                />
              </div>

              <div className="form-group">
                <span>Informe o Nire abaixo</span>
                <input className="form-control" name="nire" value={data && data.nire} onChange={evt => handleChange(evt)} />
              </div>

              <div className="form-group">
                <span>Data de abertura</span>
                <input className="form-control" name="openDate" type="date" value={data && data.openDate} onChange={evt => handleChange(evt)} max={new Date().toJSON().split('T')[0]} />
              </div>

              <div className="form-group">
                <span>Nome completo dessa empresa</span>
                <input className="form-control" name="companyName" value={data && data.companyName} onChange={evt => handleChange(evt)} />
              </div>

              <div className="form-group">
                <span>Qual o capital social registrado?</span>
                <CurrencyFormat
                  className={`form-control`}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  name="marketValue"
                  prefix={"R$"}
                  value={data && data.capital}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    handleChange(
                      { target: { name: "capital", value: value } },
                      FieldTypeObj.Currency,
                    );
                  }}
                />
              </div>
              <div className="form-group">
                <span>Para confirmar escreva o valor acima por extenso</span>
                <input className="form-control" name="capitalExtended" placeholder="Exemplo: dez mil" value={data && data.capitalExtended} onChange={evt => handleChange(evt)} />
              </div>
            </div>

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step + 1)}
              >
                Próximo
              </button>
            </div>
          </div>
        )

      case 3:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Bacana, me confirme o endereço onde a empresa da empresa?</h4>
              <div className="form-group">
                <span>CEP</span>
                <input className="form-control" name="postalCode" placeholder="CEP" value={data && data.postalCode} onChange={evt => handleChange(evt)} />
              </div>
              {data && data.postalCode && data.postalCode.length >= 8 ?
                addressFields()
                : ""}
              <div className="text-right">
                <button
                  className="btn btn-outline-warning btn-square mt-4 mb-4"
                  onClick={() => goBack()}
                >
                  Voltar
                </button>
                <button
                  className="btn btn-warning btn-square mt-4 mb-4"
                  onClick={() => setStep(step + 1)}
                >
                  Próximo
                </button>
              </div>
            </div>
          </div>
        )

      case 4:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center mb-0">Okay, possui o último contrato em word?</h4><br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="hasFile1" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "hasFile",
                      value: evt.target.checked == true ? true : false
                    }
                  })} />
                  <label class="custom-control-label" for="hasFile1">Sim</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="hasFile2" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "hasFile",
                      value: evt.target.checked == true ? false : true
                    }
                  })} />
                  <label class="custom-control-label" for="hasFile2">Não</label>
                </div>

                {data && data.hasFile === true ? (
                  <div
                    class="dropzone dz-clickable mt-3 cursor mb-2 text-center"
                    onClick={() => onUploadClick()}
                  >
                    <div class="dz-default dz-message">
                      <i className="si si-cloud-upload fa-2x" />
                      <br />
                      <span>Clique aqui para fazer upload</span>
                      <br />
                      <small>O arquivo precisa ser um PDF ou Word</small>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step + 1)}
              >
                Próximo
              </button>
            </div>
          </div>
        )
      case 5:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center mb-0">Qual o motivo que levou a baixa da empresa?</h4><br />

                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="motiveType1" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "motiveType",
                      value: evt.target.checked == true ? true : false
                    }
                  })} />
                  <label class="custom-control-label" for="motiveType1">Perdas e prejuízos</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="motiveType2" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "motiveType",
                      value: evt.target.checked == true ? false : true
                    }
                  })} />
                  <label class="custom-control-label" for="motiveType2">Outro motivo</label>
                </div>
              </div>
              {data && data.motiveType == false ?
                <div className="form-group">
                  <span>Descreva o motivo</span>
                  <textarea className="form-control" name="motive" onChange={evt => handleChange(evt)} />
                </div>
                : ""}
            </div>
            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step + 1)}
              >
                Próximo
              </button>
            </div>
          </div>
        )
      case 6:
        return (
          <div className="block-content">
            <h4 className="text-center">Ok, agora preencha a qualificação {data && data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? "do sócio atual" : "dos sócios atuais"}:</h4>
            {change && partners && partners.length == 0 ? (
              partnerForm()
            ) : (
              <p>Sócios</p>
            )}
            {change &&
              partners &&
              partners.map((item, index) => {
                if (item && !item.onlyAdm) {
                  return (
                    <div>
                      <div
                        className="block block-bordered border-2x border-warning"
                        key={`partiner-${index}`}
                      >
                        <div className="block-header">
                          <div className="block-title">Sócio: {item && item.name}</div>
                          <div className="block-tools">
                            {change && item && item.edit != true ? (
                              <button
                                className="btn btn-dual"
                                title="Editar Sócio"
                                onClick={() => editItem(index)}
                              >
                                <i className="far fa-edit" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-success"
                                title="Salvar Sócio"
                                onClick={() => saveItem(index)}
                              >
                                <i className="si si-success" /> Salvar
                              </button>
                            )}
                            <button
                              className="btn btn-dual"
                              onClick={() => removeItem(index)}
                            >
                              <i className="si si-close text-danger" />
                            </button>
                          </div>
                        </div>
                        {item && item.edit != true ? (
                          <div className="block-content row">
                            <div className="col-md-6">
                              <b className="font-w700">Nome</b>
                              <br />
                              <p>{item.name}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Nacionalidade</b>
                              <br />
                              <p>{item.nationality}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Estado civil</b>
                              <br />
                              <p>{CivilStateTranslate(item.civilState)}</p>
                            </div>
                            {item.civilState == CivilStateObj.Married ? (
                              <div className="col-md-6">
                                <b className="font-w700">Tipo de comunhão</b>
                                <br />
                                <p>{CommunionTranslate(item.communion)}</p>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-md-6">
                              <b className="font-w700">Profissão</b>
                              <br />
                              <p>{item.jobTitle}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">RG</b>
                              <br />
                              <p>{item.doc}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">CPF</b>
                              <br />
                              <p>{CpfMask(item.stateId)}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Logradouro</b>
                              <br />
                              <p>{item.street}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Numero</b>
                              <br />
                              <p>{item.number ? item.number : "S/N"}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Bairro</b>
                              <br />
                              <p>{item.neighborhood}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Complemento</b>
                              <br />
                              <p>{item.complement}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">CEP</b>
                              <br />
                              <p>{item.postalCode}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Cidade</b>
                              <br />
                              <p>{item.city}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Estado</b>
                              <br />
                              <p>{item.state}</p>
                            </div>
                            {/* <div className="col-md-6">
                          <b className="font-w700">Porcentagem do capital</b>
                          <br />
                          <p>{item.shares}</p>
                        </div> */}
                          </div>
                        ) : (
                          partnerForm(index)
                        )}
                      </div>
                      <hr />
                    </div>
                  )
                }
              })}

            {newPartner == false && partners.length > 0 ? (
              <div className="text-center">
                <button
                  className="btn btn-warning btn-square mt-4 mb-4"
                  key={"newPartnet"}
                  onClick={() => setNewPartner(!newPartner)}
                >
                  Adicionar Sócio
                </button>
              </div>
            ) : newPartner == true && partners.length > 0 ? (
              partnerForm()
            ) : (
              ""
            )}

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => goToFourth()}
              >
                Próximo
              </button>
            </div>
          </div>
        )
      case 7:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center m-0">Ok, e como está a distribuição entre os sócios?</h4>
              <p className="text-center ">Capital social registrado: R${data && data.capital && MoneyMask(data.capital, 2)}</p>

              {change && partners && partners.map((item, index) => {
                if (!item.onlyAdm) {
                  return (
                    <div className="block block-rounded block-bordered">
                      <div className="block-header">
                        <div className="block-title">
                          <div class="ml-3 text-left">
                            <p class="font-w600 mb-0">{item && item.name}</p>
                            <p class="font-size-sm font-italic text-muted mb-0">
                              {item && item.stateId && CpfMask(item.stateId)}
                            </p>
                          </div>
                        </div>
                        <div className="block-tools">
                          <div className="form-group">
                            <span>Digite a %</span>
                            <input type="number" className="form-control" min={1} max={100} value={item && item.shares} onChange={evt => handleShares(evt, index)} />
                          </div>
                          <span>
                            R${item && item.valor ? MoneyMask(item.valor, 2) : "00,00"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                }
              }
              )}

              <div className="text-right">
                <button
                  className="btn btn-outline-warning btn-square mt-4 mb-4"
                  onClick={() => goBack()}
                >
                  Voltar
                </button>
                <button
                  className="btn btn-warning btn-square mt-4 mb-4"
                  onClick={() => setStep(step + 1)}
                >
                  Próximo
                </button>
              </div>
            </div>
          </div>
        )
      case 8:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center mb-0">Quem ficará responsável pela guarda dos livros?</h4><br />
                {partners && partners.map((item, index) => (
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id={`partner=${index}`} name="example-rd-custom-inline" onChange={evt => selectDistractResponsible(item)} />
                    <label class="custom-control-label" for={`partner=${index}`}>{item.name}</label>
                  </div>
                ))}

              </div>
            </div>

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step + 1)}
              >
                Próximo
              </button>
            </div>
          </div>
        )
      default:
        return (
          <div className="content">
            {/* <div className="block block-fx-pop block-bordered border-success mt-5">
              <div className="block-header">
                <div className="block-content text-center">
                  <i className="far fa-check-circle text-success mr-2" />{" "}
                  Pronto, agora já tenho todas as informações necessárias para iniciarmos o Cancelamento e dentro de alguns instantes receberá inicialmente o Distrato Social para apreciação.
                </div>
              </div>
            </div> */}
            <ModeloDistrato data={data} partners={partners} />
            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
            </div>
          </div>
        );
        break;
    }
  }

  function sendBack() {
    ReminderController.Response(data.id, (res) => {
      toast.success(Message.Saved);
      loadReminder();
    });
  }

  async function uploadFile(fl) {
    setLoading(true);
    var formData = new FormData();
    for (let s in fl) {
      formData.append("file", fl[s]);
    }

    await ReminderFileController.Upload(formData, data.id, (res) => {
      toast.info("Upload concluído");
      setLoading(false);
      loadReminder();
    });
  }

  return (
    <div className="">
      <div className="row no-gutters justify-content-center bg-body-dark">
        <div className="hero-static col-sm-10 col-md-8 col-xl-6 d-flex align-items-center p-2 px-sm-0">
          <div className="block block-rounded block-fx-pop w-100 mb-0 overflow-hidden">
            <div className="block-content">
              {data &&
                data.status === ReminderHelper.Status.Done &&
                userData == null ? (
                <div className="block block-fx-pop block-bordered border-success mt-5">
                  <div className="block-header">
                    <div className="block-title">
                      <i className="far fa-check-circle text-success mr-2" />{" "}
                      Você já respondeu esse lembrete
                    </div>
                    <div className="block-tools"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="text-center">
                {userData ? <img src={logo} width="150px" /> : ""}
                <br />
                {step && step <= 3 ? (
                  <h3 className="text-muted mt-3 mb-1">{form == 0 ? "Contrato de abertura" : form == 1 ? "Contrato de alteração" : "Contrato de cancelamento"}</h3>
                ) : (
                  ""
                )}
                {step && step <= 3 ? (
                  <p className="text-muted mt-0 mb-5">
                    Preencha os dados para gerar o documento
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div class="progress ml-4 mt-3 mr-4 push" style={{ height: 6 }}>
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>

              {step && form == 0 ? changeOpenStep() : form == 1 ? <p className="text-center py-3">Ferramenta em construção</p> : changeCancelation()}

            </div>
          </div>
        </div>
        <ToastContainer className="toastr" />
        <input
          type="file"
          ref={inputFile}
          onChange={(event) => {
            uploadFile(event.currentTarget.files);
          }}
          style={{ visibility: "hidden", width: 10 }}
        />
      </div>
    </div>
  );
}
