import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import Storage from "@/app/core/util/Storage";
import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import InputMask from "react-input-mask";
import { ConsultController } from "@/app/core/base/controllers/ConsultController";

export default function CompanyModal({ show, onHide, infos }, props) {
  const userData = Storage.getUserData();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(infos);
  }, [show, infos]);

  function save() {
    if (!data.name || !data.doc) {
      toast.error(Message.PendingData);
    } else {
      let baseData = data;
      baseData.customerId = userData.customerId;
      setLoading(true);
      if (data.id) {
        CompanyController.Update(baseData, (res) => {
          end();
        });
      } else {
        CompanyController.Insert(baseData, (res) => {
          ConsultController.Run(res => { });
          end();
        });
      }
    }
  }

  function end() {
    toast.success(Message.Saved);
    setLoading(false);
    onHide();
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Empresa"
      onSave={() => save()}
      onSubmit={() => save()}
      isLoading={loading}
    >
      <div className="form-group">
        <span>Nome da empresa</span>
        <input
          type="text"
          className="form-control"
          name="name"
          value={data && data.name}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>CNPJ</span>
        <InputMask
          mask="99.999.999/9999-99"
          maskChar=""
          type="text"
          className="form-control"
          id="advanced-filter-document"
          name="doc"
          placeholder="99.999.999/9999-99"
          value={data && data.doc}
          onChange={(event) => {
            handleChange({
              target: {
                name: "doc",
                value: event.target.value.replace(/\D/g, ""),
              },
            });
          }}
        />
      </div>
      
      <div className="form-group">
        <label className="d-block">É uma filial?</label>
        <div className="custom-control custom-radio custom-control-inline custom-control-primary">
          <input
            type="radio"
            className="custom-control-input"
            onClick={() =>
              handleChange({ target: { value: true, name: "filial" } })
            }
            id="example-radio-custom-inline1"
            checked={data && data.filial === true ? true : false}
            name="example-radio-custom-inline"
          />
          <label
            className="custom-control-label"
            for="example-radio-custom-inline1"
          >
            Sim
          </label>
        </div>
        <div className="custom-control custom-radio custom-control-inline custom-control-primary">
          <input
            type="radio"
            className="custom-control-input"
            id="example-radio-custom-inline2"
            checked={data && data.filial === false ? true : false}
            onClick={() =>
              handleChange({ target: { value: false, name: "filial" } })
            }
            name="example-radio-custom-inline"
          />
          <label
            className="custom-control-label"
            for="example-radio-custom-inline2"
          >
            Não
          </label>
        </div>
      </div>

      {data && data.filial === true ? 
      
      <div className="form-group">
        <span>CNPJ da Matriz</span>
        <InputMask
          mask="99.999.999/9999-99"
          maskChar=""
          type="text"
          className="form-control"
          id="advanced-filter-document"
          name="doc"
          placeholder="99.999.999/9999-99"
          value={data && data.docMatriz}
          onChange={(event) => {
            handleChange({
              target: {
                name: "docMatriz",
                value: event.target.value.replace(/\D/g, ""),
              },
            });
          }}
        />
      </div>
      : ""}

      <div className="form-group">
        <span>Contato (opcional)</span>
        <input
          type="text"
          className="form-control"
          name="responsible"
          value={data && data.responsible}
          onChange={(evt) => handleChange(evt)}
        />
      </div>

      <div className="form-group">
        <span>Email (opcional)</span>
        <input
          type="text"
          className="form-control"
          name="email"
          value={data && data.email}
          onChange={(evt) => handleChange(evt)}
        />
      </div>

      <div className="form-group">
        <span>Celular (opcional)</span>
        <InputMask
          mask="(99) 99999-9999"
          maskChar=""
          type="text"
          className="form-control"
          id="advanced-filter-document"
          name="phone"
          placeholder="11 99999-9999"
          value={data && data.phone}
          onChange={(event) => {
            handleChange({
              target: {
                name: "phone",
                value: event.target.value.replace(/\D/g, ""),
              },
            });
          }}
        />
      </div>
    </Modal>
  );
}
