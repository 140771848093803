import FollowController from "@/app/core/base/controllers/FollowController";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import SearchItem from "../elements/SearchItem";
import OperationProcessModal from "../OperationProcessModal";
import ProcessFollowItem from "./ProcessFollowItem";
import {
  DateIsNear,
  DateTimeNow,
  DateToPast,
} from "@/app/core/util/DateTranslater";

export default function MyProcessList() {
  const [data, setData] = useState([]);
  const userData = Storage.getUserData();
  const followCtrl = FollowController();
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    loadFollow();
  }, []);

  function loadFollow() {
    if (userData) {
        followCtrl.ListProcess(userData.id, (res) => {
          for (let s in res) {
            res[s].process.organList = res[s].organList;
            res[s].date = DateTimeNow();
          }
          setData(res);
        });
      
    }
  }

  async function openProcess(info) {
    setShowProcessModal(true);
    setSelectedData(info);
  }

  return (
    <div className="row">
      {data &&
        data.map((item) => (
          <ProcessFollowItem
            data={item.process}
            rows="12"
            open={(info) => openProcess(info)}
          />
        ))}

      <OperationProcessModal
        data={selectedData}
        show={showProcessModal}
        onHide={() =>
          setShowProcessModal(false) | setSelectedData({}) | loadFollow()
        }
      />
    </div>
  );
}
