import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import ToDoCard from "@/app/core/layouts/main-components/customer/ToDoCard";

export default function TaskModal({ show, onHide, customerId }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  }, []);

  return (
    <CustomModal
      title="Tarefas"
      show={show}
      isLoading={loading}
      onHide={onHide}>
        <ToDoCard customerId={customerId}/>
    </CustomModal>
  );
}
