import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import HistoricController from "./HistoricController";
import Storage from "@/app/core/util/Storage";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export async function NotifyUpdate(
  processId,
  message,
  isHistory,
  channel,
  organListId,
) {
  const userData = Storage.getUserData();

  const update = {
    channel: channel == undefined ? TimelineChannelSistema : channel,
    processId: processId,
    level: userData !== null ? userData.level : 5,
    department: userData !== null ? userData.level : 5,
    important: false,
    observation: message,
    userId: userData !== null ? userData.id : null,
    organListId: organListId,
  };

  await TimelineController().Insert(update, async (res) => {});
  if (isHistory === true) {
    await HistoricController().Insert(
      { ...update, description: update.observation },
      (response) => {},
    );
  }
}

export async function NotifyHistory(
  processId,
  message,
  isHistory,
  channel,
  organListId,
) {
  const userData = Storage.getUserData();

  const update = {
    channel: channel == undefined ? TimelineChannelSistema : channel,
    processId: processId,
    level: userData !== null ? userData.level : 5,
    department: userData !== null ? userData.level : 5,
    important: false,
    observation: message,
    userId: userData !== null ? userData.id : null,
    organListId: organListId,
  };

  await HistoricController().Insert(
    { ...update, description: update.observation },
    (response) => {},
  );
}

export default function TimelineController() {
  async function Insert(timeline, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "timeline/insert",
        data: timeline,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(timeline, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "timeline/update",
        data: timeline,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Bookmark(timeline, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "timeline/bookmark",
        data: timeline,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function List(processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `timeline/process/${processId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerComunication(processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `timeline/customer-comunication/${processId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Search(processId, query, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `timeline/search/${processId}?query=${query}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Range(processId, start, end, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `timeline/range/${processId}?start=${start}&end=${end}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Update,
    Bookmark,
    List,
    Range,
    Search,
    CustomerComunication,
  };
}
