import React, { useEffect, useState, useRef } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import UsersCardComponent from "@/app/core/layouts/main-components/user/UsersCard";
import CustomerEdit from "../../customer/main/CustomerEditModal";
import {
  CustomerDeletePicture,
  CustomerPicture,
  CustomerSelect,
  CustomerUpdate,
} from "@/app/core/base/controllers/CustomerController";
import CustomerFeedbackComponent from "@/app/core/layouts/main-components/feedback/CustomerFeedbackComponent";
import ProfileComponent from "@/app/core/layouts/main-components/user/ProfileComponent";
import {
  CnpjMask,
  CpfMask,
  PhoneMask,
  PhoneTranslate,
  TelphoneMask,
} from "@/app/core/util/helpers/ContactHelper";
import InputMask from "react-input-mask";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function MainCustomer(props) {
  const [loading] = useState(false);
  const [data, setData] = useState({});
  const userData = Storage.getUserData();
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const inputFile = useRef(null);

  useEffect(() => {
    getCustomerData();
  }, []);

  function getCustomerData() {
    CustomerSelect(userData.customerId, (res) => {
      setData(res);
    });
  }

  function onUploadClick() {
    inputFile.current.click();
  }

  function uploadFile(files) {
    var formData = new FormData();
    formData.append("file", files[0]);

    CustomerPicture(data.id, formData, (res) => {
      toast.success("Salvo com sucesso");
      getCustomerData();
    });
  }

  async function updateCustomer() {
    CustomerUpdate(data, (res) => {
      toast.success("Salvo com sucesso");
      getCustomerData();
    });
  }

  function removeLogo(){
    if(window.confirm(Message.DeleteConfirm)){
      CustomerDeletePicture(userData.customerId, res => {
        getCustomerData()
      })
    }
  }

  return (
    <>
      <div className={Storage.getTheme() + " content"}>
        <Loading loading={loading} />
        <Tabs>
          <div className="bg-white">
            <div className="content">
              <div className="push">
                <Tab>
                  <button
                    style={{ margin: 10 }}
                    className="btn btn-sm btn-light"
                  >
                    <i className="far fa-user mr-1" /> Perfil
                  </button>
                </Tab>
                <Tab>
                  <button
                    style={{ margin: 10 }}
                    className="btn btn-sm btn-light"
                  >
                    <i className="si si-book-open mr-1" /> Informações
                  </button>
                </Tab>
                <Tab>
                  <button
                    style={{ margin: 10 }}
                    className="btn btn-sm btn-light"
                  >
                    <i className="si si-users mr-1" /> Usuários da plataforma
                  </button>
                </Tab>

                <Tab>
                  <button
                    style={{ margin: 10 }}
                    className="btn btn-sm btn-light"
                  >
                    <i className="far fa-image mr-1" /> Meu Logo e Site
                  </button>
                </Tab>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-md-12">
              <Panel>
                <ProfileComponent />
              </Panel>
              <Panel>
                <div className="block block-rounded">
                  <div className="block-header">
                    <div className="title">Informações</div>
                    <div className="tools">
                      <button
                        className="btn btn-sm btn-outline-info"
                        onClick={() => setShowCustomerModal(true)}
                      >
                        Editar
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <table className="table table-striped table-hover table-bordered table-vcenter font-size-sm">
                      <tbody>
                        <tr>
                          <td>Tipo de conta</td>
                          <td>
                            {data && data.type === 1
                              ? "Pessoa Física"
                              : "Pessoa Jurídica"}
                          </td>
                        </tr>
                        <tr>
                          <td>Nome</td>
                          <td>{data && data.name}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{data && data.email}</td>
                        </tr>
                        <tr>
                          <td>Telefone comercial</td>
                          <td>{data && TelphoneMask(data.phone)}</td>
                        </tr>
                        <tr>
                          <td>Telefone celular</td>
                          <td>{data && PhoneMask(data.whatsapp)}</td>
                        </tr>
                        <tr>
                          <td>Website</td>
                          <td>{data && data.website && data.website}</td>
                        </tr>
                        {data.type === 2 ? (
                          <tr>
                            <td>CNPJ</td>
                            <td>{data && CnpjMask(data.cnpj)}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td>CPF</td>
                          <td>{data && CpfMask(data.cpf)}</td>
                        </tr>
                        <tr>
                          <td>Logradouro</td>
                          <td>{data.street}</td>
                        </tr>
                        <tr>
                          <td>Número</td>
                          <td>{data.number}</td>
                        </tr>
                        <tr>
                          <td>Bairro</td>
                          <td>{data.neighborhood}</td>
                        </tr>
                        <tr>
                          <td>Complemento</td>
                          <td>{data.complement}</td>
                        </tr>
                        <tr>
                          <td>Local</td>
                          <td>
                            {data && data.city} / {data && data.uf}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Panel>
              <Panel>
                <UsersCardComponent
                  customerId={userData && userData.customerId}
                />
              </Panel>
              <Panel>
                <div className="p5 text-center row">
                  <div className="col-3"></div>
                  <div className="col-6">
                    <div className="py-5 text-center">
                      <a className="img-link">
                        <img
                          className="img-avatar img-avatar96 img-avatar-thumb"
                          src={
                            data &&
                            data.avatarImages &&
                            data.avatarImages.length > 0
                              ? data.avatarImages[0].url
                              : "assets/media/avatars/avatar10.jpg"
                          }
                          onClick={(evt) => onUploadClick()}
                          alt="Personalize com seu logo aqui"
                          title="Personalize com seu logo aqui"
                        />
                        <i className="si si-close cursor" title="Remover logo" onClick={()=> removeLogo()}/>
                      </a>
                    </div>
                    <form
                      className="dropzone dz-clickable"
                      href="javascript:void(0)"
                      onClick={(evt) => onUploadClick()}
                    >
                      <div className="dz-default dz-message">
                        <i className="si si-cloud-upload fa-2x" />
                        <br />
                        <span>Clique aqui para fazer o upload do seu logo</span>
                      </div>
                    </form>
                    <div className="form-group py-5">
                      <span>Meu Site</span>
                      <input
                        type="text"
                        className="form-control"
                        id="advanced-filter-document"
                        name="website"
                        placeholder="https://"
                        value={data && data.website}
                        onChange={(event) =>
                          setData({ ...data, website: event.target.value })
                        }
                      />

                      <button
                        className="btn btn-sm btn-info mt-5"
                        onClick={() => updateCustomer()}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
              </Panel>
            </div>
          </div>
        </Tabs>
        <input
          type="file"
          ref={inputFile}
          onChange={(event) => {
            uploadFile(event.currentTarget.files);
          }}
          style={{ visibility: "hidden", width: 10 }}
        />
        <CustomerEdit
          data={data}
          show={showCustomerModal}
          onHide={() => setShowCustomerModal(false) | getCustomerData()}
        />
      </div>
    </>
  );
}
