
import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import CopyToClipboard from "react-copy-to-clipboard";
import GetBaseUrl from "@/app/core/base/BaseAddress";

export default function SendFulfillmentContractModal({ show, onHide, infos }) {
    const [loading] = useState(false);

    useEffect(() => {}, []);

    function handleChanges(){}

    function sendEmail(){}

    function sendWhats(){}

    function notifyCopy(){}
    
    return (
        <CustomModal
            title="Enviar para preenchimento"
            show={show}
            isLoading={loading}
            onHide={onHide}>
            <div className="content row">
                <div className="col-12">
                    <div className="form-group">
                        <span>Enviar por email</span>
                        <div className="input-group">
                            <input
                                className="form-control"
                                name="email"
                                placeholder="Email do empresário"
                                onChange={(evt) => handleChanges(evt)}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-success"
                                    onClick={() => sendEmail()}
                                >
                                    <i className="fa fa-location-arrow"></i> Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <span>Outras opções de compartilhamento</span>
                        <br />
                        <br />
                        <button
                            className="btn btn-success mr-2"
                            onClick={() => sendWhats()}
                        >
                            <i className="fab fa-whatsapp"></i> WhatsApp
                        </button>
                        OU
                        <CopyToClipboard
                            text={`${GetBaseUrl()}#/confirm/${infos && infos.id
                                }?customer=true`}
                        >
                            <button
                                type="button"
                                className="btn btn-info ml-2"
                                title="copiar"
                                onClick={() => notifyCopy()}
                            >
                                <i className="far fa-copy mr-1" /> Copiar URL
                            </button>
                        </CopyToClipboard>

                        <p className="mt-5 font-w700">

                            <b className="font-w700">
                                Informações importantes:
                            </b>
                            <br />
                            <br />
                            1 – A Hasa não aparecerá nas mensagens enviadas ao cliente final (Empresário);
                            <br />
                            <br />
                            2 - Após o aceite do empresário, o serviço iniciará automaticamente;
                            <br />
                            <br />
                            3 – Não somos responsáveis por acordos e pagamentos financeiros entre o parceiro Hasa e o cliente final;
                            <br />
                            <br />
                            4 – Nossa plataforma tem o objetivo de entregar ferramentas poderosas para nossos parceiros, caso possua dúvidas sobre a usabilidade fale com nosso time para orientação.

                        </p>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
}
