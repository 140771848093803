import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function ToDoController() {
  async function Insert(toDo, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "toDo/insert",
        data: toDo,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(toDo, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "toDo/update",
        data: toDo,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function List(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `toDo/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(toDoId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `toDo/delete/${toDoId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Update,
    List,
    Delete,
  };
}
