import ToDoController from "@/app/core/base/controllers/ToDoController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function ToDoCard({ customerId }) {
    const [data, setData] = useState([]);
    const [done, setDone] = useState([]);
    const [description, setDescription] = useState("");

    useEffect(() => {
        loadData()
    }, [customerId])

    function loadData() {
        ToDoController().List(customerId, res => {
            setData(res.filter(x => x.done == false));
            setDone(res.filter(x => x.done == true));
        })
    }

    function saveTask(e){
        e.preventDefault();
        if(description.length == 0){
            toast.error(Message.PendingData)
        }else{
            ToDoController().Insert({customerId: parseInt(customerId), description}, res => {
                setDescription("")
                loadData();
            })
        }
    }

    function handleChange(evt, item){
        let baseData = item;
        baseData.done = evt.target.checked;
        ToDoController().Update(baseData, res => {
            loadData();
        })
    }

    function handleDoneChange(evt, item){
        let baseData = item;
        baseData.done = evt.target.checked;
        ToDoController().Update(baseData, res => {
            loadData();
        })
    }

    function deleteTask(item){
        if(window.confirm(Message.DeleteConfirm)){
            ToDoController().Delete(item.id, res => {
                loadData();
            })
        }
    }

    return (
        <div className="block block-rounded block-transparent">
            <div className="block-header ml-0 mr-0 pl-0 pr-0 pb-0 mb-0 mt-3">
                <div className="block-title">Tarefas</div>
            </div>
            <div className="block-content ml-0 mr-0 pl-0 pr-0">
                <form onSubmit={e => saveTask(e)}>
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control" id="example-group3-input2" name="example-group3-input2" placeholder="Descreva a tarefa" value={description} onChange={evt => setDescription(evt.target.value)} />
                            <div class="input-group-append">
                                <button type="submit" class="btn btn-dark">Adicionar</button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="js-task-list">
                    {data && data.map((item, index) => (
                        <div class="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn" data-task-id="9" data-task-completed="false" data-task-starred="false">
                            <table class="table table-borderless table-vcenter mb-0">
                                <tbody>
                                    <tr>
                                        <td class="text-center pr-0" style={{ width: 38 }}>
                                            <div class="js-task-status custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-primary custom-control-lg">
                                                <input type="checkbox" class="custom-control-input" id={index} name={index} onChange={(evt) => handleChange(evt, item)}/>
                                                <label class="custom-control-label" for={index}></label>
                                            </div>
                                        </td>
                                        <td class="js-task-content font-w600 pl-0">
                                            {item.description}
                                        </td>
                                        <td class="text-right" style={{ width: 100 }}>
                                            <button type="button" class="js-task-remove btn btn-sm btn-link text-danger" onClick={()=> deleteTask(item)}>
                                                <i class="fa fa-times fa-fw"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                    {done.length > 0 ? <h5 className="text-muted py-2">Concluídos</h5> : ""}
                    {done && done.map((item, index) => (
                        <div class="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn" data-task-id="9" data-task-completed="false" data-task-starred="false">
                            <table class="table table-borderless table-vcenter mb-0">
                                <tbody>
                                    <tr>
                                        <td class="text-center pr-0" style={{ width: 38 }}>
                                            <div class="js-task-status custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-primary custom-control-lg">
                                                <input type="checkbox" class="custom-control-input" id={`done-${index}`} name={`done-${index}`} onChange={(evt) => handleDoneChange(evt, item)} checked={item.done}/>
                                                <label class="custom-control-label" for={`done-${index}`}></label>
                                            </div>
                                        </td>
                                        <td class="js-task-content font-w600 pl-0">
                                            {item.description}
                                        </td>
                                        <td class="text-right" style={{ width: 40 }}>
                                            <button type="button" class="js-task-remove btn btn-sm btn-link text-danger" onClick={()=> deleteTask(item)}>
                                                <i class="fa fa-times fa-fw"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

}