import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import SampleTable from "@/app/core/components/table/premade/SampleTable";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import DiscountRulesForm from "./DiscountRulesForm";
import { PlanController } from "@/app/core/base/controllers/PlanController";
import PlanFormModal from "./PlanFormModal";

export default function PlansCard() {
  const [showModal, setShowModal] = useState(false);
  const [service, setService] = useState(null);
  const [data, setData] = useState([]);
  
  const cols = [
    { header: "Nome", isSortable: true, name: "name" },
    { header: "Valor", isSortable: true, name: "price" },
    { header: "Atualizado em", isSortable: true, name: "updatedDate" },
    { header: "Criado em", isSortable: true, name: "createdDate" },
  ];

  useEffect(() => {
    loadRules();
  }, []);

  async function loadRules() {
    PlanController.List((res) => {
      for (let s in res) {
        res[s].createdDate = DateTranslate(res[s].createdDate);
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
      }
      console.log("res", res);
      setData(res);
    });
  }

  function deleteRule(data) {
    if (
      window.confirm(Message.DeleteConfirm)
    ) {
        PlanController.Delete(data.id, (res) => {
        toast.info(Message.DeleteSuccess);
        loadRules();
      });
    }
  }

  function openService(item) {
    setService(item.data);
    setShowModal(true);
  }

  return (
    <>
      <Card
        title="Planos"
        action={() => setShowModal(!showModal)}
        icon="plus"
      >
        <SampleTable
          title="Planos"
          cols={cols}
          data={data}
          remove={(task) => deleteRule(task.data)}
          refresh={() => loadRules()}
          newItem={() => setShowModal(true)}
          open={(item) => openService(item)}
        />
      </Card>

      <PlanFormModal
        data={service}
        show={showModal}
        onHide={() =>
          setShowModal(!showModal) | loadRules() | setService(null)
        }
      />
    </>
  );
}
