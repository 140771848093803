import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import Storage from "@/app/core/util/Storage";
import { CnpjMask, CpfMask } from "@/app/core/util/helpers/ContactHelper";
import { OrganHelper } from "@/app/core/util/helpers/OrganHelper";
import React, { useEffect, useState } from "react";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import ElaboratorTutorialModal from "./components/ElaboratorTutorialModal";
import { Tab, Tabs } from "react-bootstrap";
import SendFulfillmentContractModal from "./components/SendFulfillmentContractModal";
import NewContractModal from "./components/NewContractModal";
import { ResumeCard } from "../../dashboard/components/panels/administratorElements/ResumeCard";
import ElaboratorCard from "../../customerProfile/main/components/ElaboratorCard";
import BudgetCard from "../../customerProfile/main/components/BudgetCard";
import CompaniesCard from "../../customerProfile/main/components/CompaniesCard";
import ResumeTaskCard from "../../customerProfile/main/components/ResumeTaskCard";
import ReminderCard from "../../customerProfile/main/components/ReminderCard";

export default function ElaboratorComponent({ customerId }) {
  const userData = Storage.getUserData();
  const [customer, setCustomer] = useState(null);
  const [data, setData] = useState([
    {
      id: 1,
      name: "Danglars", 
      tipo: "Abertura",
      createdDate: "11/10/2024 as 10:30",
      status: 2,
    },
    {
      id: 1,
      name: "Caderouse", 
      tipo: "Alteração",
      createdDate: "11/10/2024 as 10:30",
      status: 0,
    },
    {
      id: 1,
      name: "Vileforte", 
      tipo: "Cancelamento",
      createdDate: "11/10/2024 as 10:30",
      status: 1,
    }
  ]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [showNewContractModal, setShowNewContractModal] = useState(false);
  const [showNewBudgetModal, setShowNewBudgetModal] = useState(false);
  const [showFulfillmentModal, setShowFulfillmentModal] = useState(false);
  const [company, setCompany] = useState({});
  const date = new Date();
  const [change, setChange] = useState(date.getTime());
  const timestamp = new Date().getTime();

  const [report, setReport] = useState({
    total: 0,
    regular: 0,
    irregular: 0,
  });

  useEffect(() => {
    if (userData && userData.level != 5) {
      setCustomer(customerId);
      getCompanies(customerId);
    } else {
      setCustomer(userData.customerId);
      getCompanies(userData.customerId);
    }
  }, []);

  function notifyChange() {
    setChange(date.getTime());
  }

  function getCompanies(id) {
  }

  function deleteCompany(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      CompanyController.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        getCompanies(customer);
      });
    }
  }

  function openCompany(item) {
    // setCompany(item);
    if (userData && userData.level == 5) {
      // setShowCompany(true);
    } else {
      // setShowOrganModal(true);
    }
  }

  function checkOrUncheck(evt, index) {
    let base = data;
    base[index].checked = evt.target.checked;
    setData(base);
    notifyChange();
  }

  function newService(item) {
    setSelected(item);
    setShowFulfillmentModal(true);
  }

  function requestConsultation() {
    const selecteds = data.filter((x) => x.checked == true);
    if (selecteds.length == 0) {
      toast.error(Message.PendingData);
    } else {
      let fifiteen = 86400 * 15;
      let Ok = false;
      for (let s in selecteds) {
        const dDay = selecteds[s].lastCheckedTime + fifiteen;
        if (
          dDay > timestamp &&
          selecteds[s].status != OrganHelper.CompanyOrganStatus.Pending &&
          selecteds[s].status != OrganHelper.CompanyOrganStatus.NotApply
        ) {
          toast.error(
            `Atenção! As consultas são realizadas a cada 15 dias. E a empresa ${selecteds[s].name} já foi consultada no período.`,
          );
        } else {
          CompanyController.Check(selecteds[s].id, (res) => {
            toast.success(
              "Solicitado a consulta de situação cadastral, em breve retornaremos",
            );
          });
        }
      }
      setData([]);
      notifyChange();
      getCompanies(customer);
    }
  }

  return (
    <div className="content mr-0 pl-0 pr-0">
      <div className="row mr-0 pr-0">
        <div className="col-md-10 mb-3">
          <h2 className="mb-0 ml-3 mt-2">
            <strong className="font-w700">Elaborador Automático de Contratos</strong>
            <br />
          </h2>
            <p className="text-muted ml-3 mb-1 mt-2">
              O elaborador automático de contratos é uma ferramenta do Hasa Pro desenvolvida para ajudar você a elaborar contratos de Abertura, Alteração e Cancelamento em tempo recorde!
<br/>
<br/>
              Clique em Iniciar Contrato para começar e acompanhe as elaborações em andamento no painel abaixo.
  
            </p>
        </div>
        <div className="col-md-2 mb-3 text-right">
          <button className="btn btn-info" onClick={() => setShowModal(true)}>
            <i className="si si-question" /> Tutorial
          </button>
        </div>


        <div className="col-md-12 mt-4">
          <Tabs defaultActiveKey="resumo">
            <Tab eventKey="resumo" title="Resumo">
              <div className="block block-bordered border-2x">
                <div className="block-header">
                  <div className="block-title">Contratos</div>
                  <div className="block-tools">
                    {/* <button className="btn btn-outline-success" onClick={() => requestConsultation()}>({change && data && data.filter(x => x.checked == true).length}) Solicitar consulta</button> */}
                    {userData && userData.level == 5 ? (
                      <button
                        className="btn btn-outline-info"
                        onClick={() => setShowNewContractModal(true)}
                      >
                        <i className="si si-plus" /> Iniciar contrato
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="block-content">
                  <table className="table table-hover table-borderless">
                    <thead>
                      <tr>
                        <th>Empresa</th>
                        <th>Tipo</th>
                        <th>Data de início</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {change &&
                        data &&
                        data.map((item, index) => (
                          <tr key={item.id}>
                            <td>
                              {item.name}
                            </td>
                            <td>
                              {item.tipo}
                            </td>
                            <td>{item.createdDate}</td>
                            <td>
                              {item.status ==
                                CustomerHelper().CompanyStatus.NotConsulted ? (
                                <span className="badge badge-secondary">
                                  Não iniciado
                                </span>
                              ) : item.status ==
                                CustomerHelper().CompanyStatus.Consulting ? (
                                <span className="badge badge-warning">
                                  Parcialmente preenchido
                                </span>
                              ) : item.status == 2 ? (
                                <span className="badge badge-success">Concluído</span>
                              ) : ""}
                            </td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-hero-sm btn-hero-success"
                                onClick={() => newService(item)}
                              >
                                Enviar p/ preenchimento
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-dual ml-2 mr-2"
                                title="Editar"
                                onClick={() =>
                                  window.open(`#/drafter/${item.id}`, "_blank")
                                }
                              >
                                <i className="fa fa-fw fa-pencil-alt" />
                              </button>
                                <button
                                  className="btn btn-sm btn-dual ml-2 mr-2"
                                  title="Visualizar"
                                  onClick={() => openCompany(item)}
                                >
                                  <i class="si si-cloud-download" />
                                </button>
                              <button
                                className="btn btn-sm btn-dual ml-2 mr-2"
                                title="Excluir"
                                onClick={() => deleteCompany(item)}
                              >
                                <i class="fas fa-times" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>

          <div className="col-md-3">
            <ResumeTaskCard customerId={customerId != undefined ? customerId : userData.customerId} />
          </div>
          <div className="col-md-3">
            <BudgetCard customerId={customerId != undefined ? customerId : userData.customerId} />
          </div>
          <div className="col-md-3">
            <CompaniesCard customerId={customerId != undefined ? customerId : userData.customerId} />
          </div>
          <div className="col-md-3">
            <ReminderCard customerId={customerId != undefined ? customerId : userData.customerId} />
          </div>
      </div>
      <ElaboratorTutorialModal
        show={showModal}
        infos={selected}
        onHide={() => setShowModal(false)} />

      <SendFulfillmentContractModal
        show={showFulfillmentModal}
        infos={selected}
        onHide={() => setShowFulfillmentModal(false)} />
      
      <NewContractModal
        show={showNewContractModal}
        infos={selected}
        onHide={() => setShowNewContractModal(false)} />

    </div>
  );
}
