import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { Message } from "@/app/core/util/messages/GeralMessages";
import DiscountRulesController from "@/app/core/base/controllers/DiscountRulesController";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { ServiceList } from "@/app/core/base/controllers/ServicesController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Dropdown } from "react-bootstrap";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import { PlanController } from "@/app/core/base/controllers/PlanController";

export default function PlanFormModal(props) {
  const { data, show, onHide, submit, isLoading } = props;

  const [infos, setInfos] = useState({});
  const [component, setComponent] = useState({});
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  
  useEffect(() => {
      if (data !== null && data.id !== undefined) {
          setInfos(data);
        } else {
            setInfos({});
        }
    }, [data]);
    
    function notifyChange() {
      setChange(date.getTime());
    }

  function saveItem(){
    PlanController.InsertItem({...component, planId: infos.id}, res => {
        loadPlan()
        setComponent({})
    })
  }

  function loadPlan(){
    PlanController.Get(infos.id, res => {
        setInfos({...infos, items: res.items});
        notifyChange()
    })
  }

  function handleItemChange(evt, type){
    setComponent(OnDataChange(component, evt, type));
  }

  async function saveService() {
    if (!infos.name) {
      toast.error("Nome é obrigatório");
    } else if (!infos.price) {
      toast.error("Valor é inválido");
    } else if (infos.id === undefined) {
      await PlanController.Insert(infos, res => {
        toast.success(Message.Saved);
        onHide();
      })
    } else {
      await PlanController.Update(infos, res => {
        toast.success(Message.Saved);
        onHide();
      })
    }
  }

  async function removeItem(id) {
    await PlanController.DeleteItem(id, res => {
      loadPlan()
      toast.success(Message.DeleteSuccess);
    })
  }

  function handleChange(evt, type) {
    setInfos(OnDataChange(infos, evt, type));
  }

  return (
    <Modal
      title="Serviço"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => saveService()}
    >
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label for="example-masked-time">Nome</label>
            <input
              className="form-control"
              name="name"
              value={infos.name}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group">
            <label for="example-masked-time">Preço Mensal</label>
            <input
              type="number"
              className="form-control"
              name="price"
              value={infos.price}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Currency)}
            />
          </div>
          <div className="form-group">
            <label for="example-masked-time">Preço Trimestral</label>
            <input
              type="number"
              className="form-control"
              name="priceQuartely"
              value={infos.priceQuartely}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Currency)}
            />
          </div>
          <div className="form-group">
            <label for="example-masked-time">Preço Anual</label>
            <input
              type="number"
              className="form-control"
              name="priceYerly"
              value={infos.priceYerly}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Currency)}
            />
          </div>

          {infos && infos.id !== undefined ? (
            <div className="col-md-12">
              <div className="block block-fx-pop">
                <div className="block-header">
                  <div className="block-title">Itens</div>
                  <div className="block-tools">
                    <Dropdown>
                      <Dropdown.Toggle variant="light">Adicionar</Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: 450 }}>
                        <div className="form-group">
                            <span>Nome</span>
                            <input className="form-control" name="name" value={component.name} onChange={evt => handleItemChange(evt)}/>
                        </div>
                        <div className="form-group">
                            <span>Componente a liberar</span>
                            <select className="form-control" name="component" value={component.component} onChange={evt => handleItemChange(evt)}>
                                <option>Selecione</option>
                                {CustomerHelper().ProComponentList.map(item => (
                                    <option value={item.value}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <span>Limitar quantidade (opcional):</span>
                            <input className="form-control" type="number" name="number" value={component.number} onChange={evt => handleItemChange(evt)}/>
                        </div>

                        <button className="btn btn-block btn-info" onClick={()=> saveItem()}>Salvar</button>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="block-content">
                  {change && infos && infos.items && infos.items.map((item) => (
                    <div className="block block-rounded block-bordered ribbon ribbon-left ribbon-bookmark ribbon-warning">
                      <div className="block-header block-header-default">
                        <small>
                          {item&& item.name}
                        </small>
                        <div className="block-tools">
                          <button
                            className="btn btn-sm btn-white"
                            onClick={() => removeItem(item.id)}
                          >
                            <i className="si si-close text-danger" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
}

