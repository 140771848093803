import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";

export async function GetUser(id) {
  let response;
  await BaseService(
    {
      method: "GET",
      url: `select-user?userId=${id}&`,
    },
    (res) => {
      response = res;
    },
    (err) => {
      toast.error("Não foi possivel carregar o processo");
    },
  );
  return response;
}

export function ValidaCNPJ(cnpj) {
  var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  var c = String(cnpj).replace(/[^\d]/g, "");

  if (c.length !== 14) return false;

  if (/0{14}/.test(c)) return false;

  for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
  if (c[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

  for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
  if (c[13] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

  return true;
}

export function CPFValidator(strCPF) {
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF == "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function PasswordIsStrong(password) {
  let senha = password;
  let forca = 0;
  if (senha.length >= 4 && senha.length <= 7) {
    forca += 10;
  } else if (senha.length > 7) {
    forca += 25;
  }
  if (senha.match(/[a-z]+/)) {
    forca += 10;
  }
  if (senha.match(/[A-Z]+/)) {
    forca += 20;
  }
  if (senha.match(/d+/)) {
    forca += 20;
  }
  if (senha.match(/W+/)) {
    forca += 25;
  }

  if (forca < 30 || forca === 35) {
    return false;
  } else if (forca > 35 && forca < 60) {
    return true;
  } else if (forca >= 60 && forca < 85) {
    return true;
  } else {
    return true;
  }
}

export const ContractTypeList = [
  { name: "CLT", value: 0 },
  { name: "PJ", value: 1 },
];

export const ContractType = { CLT: 0, PJ: 1 };

export const PreparationStepList = [
  { name: "Esteira padrão", value: 0 },
  { name: "Conferência", value: 1 },
];

export const PreparationStep = {
  Track: 0,
  Review: 1,
};

export function PreparationStepTranslate(i) {
  const res = PreparationStepList.filter((x) => x.value == i);
  if (res.length == 0) {
    return "S.I.";
  } else {
    return res[0].name;
  }
}

export const CivilState = [
  { name: "Solteiro(a)", value: 0 },
  { name: "Casado(a)", value: 1 },
  { name: "Divorciado(a)", value: 2 },
  { name: "Viúvo(a)", value: 3 },
];

export const CivilStateObj = {
  Single: 0,
  Married: 1,
  Divorced: 2,
};

export function CivilStateTranslate(i) {
  const res = CivilState.filter((x) => x.value == i);
  if (res.length == 0) {
    return "S.I.";
  } else {
    return res[0].name;
  }
}

export const Communion = [
  { name: "Comunhão parcial de bens", value: 0 },
  { name: "Comunhão universal de bens", value: 1 },
  { name: "Separação total de bens", value: 2 },
];

export const CommunionObj = {
  Partial: 0,
  Universal: 1,
  Separated: 2,
};

export function CommunionTranslate(i) {
  const res = Communion.filter((x) => x.value == i);
  if (res.length == 0) {
    return "S.I.";
  } else {
    return res[0].name;
  }
}
