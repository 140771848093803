import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import SampleTable from "@/app/core/components/table/premade/SampleTable";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import DiscountRulesController from "@/app/core/base/controllers/DiscountRulesController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import DiscountRulesForm from "./DiscountRulesForm";

export default function DiscountRulesCard() {
  const [showModal, setShowModal] = useState(false);
  const [service, setService] = useState(null);
  const [data, setData] = useState([]);
  
  const discountRulesCtrl = DiscountRulesController();

  const cols = [
    { header: "Mínimo", isSortable: true, name: "min" },
    { header: "Máximo", isSortable: true, name: "max" },
    { header: "Valor", isSortable: true, name: "value" },
    { header: "Tipo de desconto", isSortable: true, name: "type", switch: {
        0:<span className="nav-main-link-badge badge badge-pill badge-warning">Preço fixo</span>,
        1:<span className="nav-main-link-badge badge badge-pill badge-success">Porcento</span>
    }},
    { header: "Atualizado em", isSortable: true, name: "updatedDate" },
    { header: "Criado em", isSortable: true, name: "createdDate" },
  ];

  useEffect(() => {
    loadRules();
  }, []);

  async function loadRules() {
    await discountRulesCtrl.Get((res) => {
      for (let s in res) {
        res[s].createdDate = DateTranslate(res[s].createdDate);
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
      }
      console.log("res", res);
      setData(res);
    });
  }

  function deleteRule(data) {
    if (
      window.confirm(Message.DeleteConfirm)
    ) {
        discountRulesCtrl.Delete(data.id, (res) => {
        toast.info(Message.DeleteSuccess);
        loadRules();
      });
    }
  }

  function openService(item) {
    setService(item.data);
    setShowModal(true);
  }

  return (
    <>
      <Card
        title="Descontos"
        action={() => setShowModal(!showModal)}
        icon="plus"
      >
        <SampleTable
          title="Serviços"
          cols={cols}
          data={data}
          remove={(task) => deleteRule(task.data)}
          refresh={() => loadRules()}
          newItem={() => setShowModal(true)}
          open={(item) => openService(item)}
        />
      </Card>

      <DiscountRulesForm
        data={service}
        show={showModal}
        onHide={() =>
          setShowModal(!showModal) | loadRules() | setService(null)
        }
      />
    </>
  );
}
