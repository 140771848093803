import React from "react";
import ServicesCardComponent from "./ServicesCard";
import DailyRatesComponent from "./DailyRatesCard";
import UsersCardComponent from "@/app/core/layouts/main-components/user/UsersCard";
import MotivationalComponent from "./MotivationalCard";
import TemplateDocsCard from "./TemplateDocsCard";
import ShortcutCard from "./ShortcutCard";
import { ToastContainer, toast } from "react-toastify";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import "react-toastify/dist/ReactToastify.css";
import ExigenciesComponent from "./ExigenciesCard";
import OrgansCard from "./OrgansCard";
import TeamsCard from "./TeamsCard";
import FeedbacksCard from "./FeedbacksCard";
import TermsComponent from "@/app/core/layouts/main-components/documents/terms/TermsComponent";
import ResendComponent from "./ResendComponent";
import ObjectiveComponent from "./ObjectiveComponent";
import SenderCard from "./SenderCard";
import Storage from "@/app/core/util/Storage";
import DiscountRulesCard from "./DiscountRulesCard";
import PlansCard from "./PlansCard";

function MainSettingsComponent(props) {
  const userData = Storage.getUserData();

  return (
    <>
      <div className="content">
        <h1 className="flex-sm-fill font-size-h2 font-w400 mb-sm-2">
          Configurações
        </h1>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <Tabs>
              <div
                className="nav nav-tabs nav-tabs-block nav-justified js-tabs-enabled"
                style={{ padding: 7 }}
              >
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="fa fa-users"></i> Usuários
                  </button>
                </Tab>
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="fa fa-chart-bar"></i> Serviços
                  </button>
                </Tab>
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="fa fa-tasks"></i> Atividades diárias
                  </button>
                </Tab>
                {/* {userData && userData.level == 10 ? */}
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="fa fa-images"></i> Mensagens
                  </button>
                </Tab>
                {/* : ""} */}
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="fa fa-folder"></i> Pastas de templates
                  </button>
                </Tab>
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="fa fa-link"></i> Atalhos
                  </button>
                </Tab>
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="far fa-file-alt"></i> Modelos de exigências
                  </button>
                </Tab>
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="far fa-building"></i> Órgãos
                  </button>
                </Tab>
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="far fa-object-group"></i> Equipes
                  </button>
                </Tab>

                {userData && userData.level == 10 ? (
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="si si-question"></i> Feedbacks
                    </button>
                  </Tab>
                ) : (
                  ""
                )}

                {userData && userData.level == 10 ? (
                  <Tab>
                    <button
                      type="button"
                      class="btn btn-dual mr-1"
                      data-toggle="layout"
                      alt="Documentos"
                    >
                      <i class="fa fa-file-signature"></i> Termos & Políticas
                    </button>
                  </Tab>
                ) : (
                  ""
                )}
                <Tab>
                  <button
                    type="button"
                    class="btn btn-dual mr-1"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i class="fa fa-robot"></i> 2º Aviso Automático
                  </button>
                </Tab>

                {userData && userData.level == 10 ? (
                  <Tab>
                    <button class="btn btn-dual mr-1">
                      <i class="fa fa-chart-line"></i> Objetivos
                    </button>
                  </Tab>
                ) : (
                  ""
                )}
                <Tab>
                  <button class="btn btn-dual mr-1">
                    <i class="si si-envelope-open"></i> Remetentes
                  </button>
                </Tab>
                <Tab>
                  <button
                    type="button"
                    className="btn btn-dual mr-1"
                    alt="Descontos"
                  >
                    <i className="si si-tag"></i> Regras de descontos
                  </button>
                </Tab>
                <Tab>
                  <button
                    type="button"
                    className="btn btn-dual mr-1"
                    alt="Descontos"
                  >
                    <i className="far fa-money-bill-alt"></i> Planos
                  </button>
                </Tab>
              </div>
              <div className="block block-body">
                <Panel>
                  <UsersCardComponent toast={toast} />
                </Panel>
                <Panel>
                  <ServicesCardComponent toast={toast} />
                </Panel>

                <Panel>
                  <DailyRatesComponent toast={toast} />
                </Panel>
                {/* {userData && userData.level == 10 ?
                  : ""} */}
                <Panel>
                  <MotivationalComponent toast={toast} />
                </Panel>
                <Panel>
                  <TemplateDocsCard toast={toast} />
                </Panel>
                <Panel>
                  <ShortcutCard toast={toast} />
                </Panel>
                <Panel>
                  <ExigenciesComponent toast={toast} />
                </Panel>
                <Panel>
                  <OrgansCard />
                </Panel>

                <Panel>
                  <TeamsCard />
                </Panel>

                {userData && userData.level == 10 ? (
                  <Panel>
                    <FeedbacksCard />
                  </Panel>
                ) : (
                  ""
                )}
                {userData && userData.level == 10 ? (
                  <Panel>
                    <TermsComponent />
                  </Panel>
                ) : (
                  ""
                )}
                <Panel>
                  <ResendComponent />
                </Panel>

                {userData && userData.level == 10 ? (
                  <Panel>
                    <ObjectiveComponent />
                  </Panel>
                ) : (
                  ""
                )}
                <Panel>
                  <SenderCard />
                </Panel>
                <Panel>
                  <DiscountRulesCard />
                </Panel>
                <Panel>
                  <PlansCard />
                </Panel>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
      <ToastContainer className="toastr" />
    </>
  );
}

export default MainSettingsComponent;
