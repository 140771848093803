import React, { useEffect, useState } from "react";
import ToDoController from "@/app/core/base/controllers/ToDoController";
import TaskModal from "./TaskModal";

export default function ElaboratorCard({reload, customerId }) {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);

  useEffect(()=>{
    loadTasks()
},[customerId])

function loadTasks(){
    
}

  function openItem() {
    window.location = "#/elaborator";
    // setShowModal(true);
  }

  return (
    <div>
      <div
        className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1 cursor"
        style={{ height: 210 }}
        onClick={() => openItem()}
      >
        <div className="block-header">
          <div className="block-title">
            <i className="far fa-file-alt fa-2x"></i>
          </div>
          <div className="block-tools">
            <i
              className="si si-question "
              title="O elaborador automático de contratos é uma ferramenta do Hasa Pro desenvolvida para ajudar você a elaborar contratos de Abertura, Alteração e Cancelamento em tempo recorde!"
            />
          </div>
        </div>
        <div className="block-content pt-0">
          {data && data.length == 0 ? (
            <div>
              <h3 className=" mt-0">
                Elaborador automático de contratos
              </h3>
            </div>
          ) : (
            <div>
              <h3 className=" mt-0">
                {data && data.length}{" "}
                {data && data.length == 1 ? "Tarefa" : "Tarefas"}  em aberto
              </h3>

              <ul>
                {data && data.map((item, index) => {
                    if(index < 3 && item.description && item.description.length > 0){
                        console.log("Tarefa", item)
                        return (
                            <li className="text-dark"> {item.description}</li>
                        )
                    }
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
      <TaskModal
        show={showModal}
        customerId={customerId}
        reload={() => reload() }
        onHide={() => setShowModal(false) | loadTasks()}
      />

    </div>
  );
}
