import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { StateList } from "@/app/core/base/controllers/StateController";
import {
  CustomerInsert,
  CustomerUpdate,
} from "@/app/core/base/controllers/CustomerController";
import Storage from "@/app/core/util/Storage";
import { UserInsert } from "@/app/core/base/controllers/UsersController";
import {
  SendCustomerChange,
  SendUserInvitation,
} from "@/app/core/base/controllers/ContactController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import FollowingCustomerCard from "@/app/core/layouts/main-components/customer/FolloingCustomercard";
import { PlanController } from "@/app/core/base/controllers/PlanController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { DateTranslate, DateTranslateToInput, OnlyDateInputTranslate, OnlyDateTranslate } from "@/app/core/util/DateTranslater";

function CustomerModalComponent({ show, onHide, submit, data }) {
  const [formValidation, setFormValidation] = useState({});
  const [stateList, setStateList] = useState([]);
  const [plans, setPlans] = useState([]);
  const [customer, setCustomer] = useState({});
  const [oldCustomer, setOldCustomer] = useState({});
  const [picture, setPicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const userData = Storage.getUserData();
  const customerHelper = CustomerHelper();
  const date = new Date();

  const [change, setChange] = useState(date.getTime());
  function notifyChange() {
    setChange(date.getTime());
  }

  useEffect(() => {
    if (data !== undefined && data !== null) {
      let oldDAta = data;
      oldDAta.viewTimeType = 0
      setCustomer(oldDAta);
      setOldCustomer(oldDAta);
    } else {
      setCustomer({});
    }
    loadStates();
    loadPlans()
  }, [data]);

  function loadPlans() {
    PlanController.List(res => setPlans(res));
  }

  function loadStates() {
    StateList((res) => {
      setStateList(res);
    });
  }

  function uplodaPicture(file) {
    var formData = new FormData();
    formData.append("file", file);
  }

  function handleChange(evt, type) {
    setCustomer(OnDataChange(customer, evt, type));
    notifyChange()
  }

  function update() {
    if (
      customer.name === undefined ||
      customer.email === undefined ||
      customer.city === undefined ||
      customer.uf === undefined ||
      customer.zipcode === undefined ||
      customer.phone === undefined
    ) {
      toast.error("Preencha todos os dados para poder salvar");
    } else {
      setLoading(true);
      if (picture === null) {
        if (data.picture === null || data.picture === undefined) {
          setPicture("");
        } else {
          setPicture(data.picture);
        }
      }

      if (customer.id !== undefined) {
        if (userData.level === 5) {
          SendCustomerChange(oldCustomer, customer, userData);
        }

        CustomerUpdate(customer, (res) => {
          toast.success("Salvo com sucesso");
          onHide();
          setLoading(false);
        });
      } else {
        CustomerInsert(customer, (res) => {
          createUser(res);
          setLoading(false);
        });
      }
    }
  }

  function activatePlan(item, viewTimeType) {
    if (window.confirm(Message.Confirm)) {
      let base = customer;
      base.planId = item.id;
      base.timeType = viewTimeType;

      if (base.timeType === 0) {
        base.expirationDate = expirationDate(365);
      }
      if (base.timeType === 1) {
        base.expirationDate = expirationDate(90);
      }
      if (base.timeType === 2) {
        base.expirationDate = expirationDate(30);
      }

      base.frequencyType = CustomerHelper().FrequencyType.Monthly;
      base.frequency = 3;

      setCustomer(base);
      CustomerUpdate(base, res => {
        toast.success("Plano selecionado");
        onHide();
      })
    }
  }

  function expirationDate(days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }

  function createUser(response) {
    const user = {
      firstName: response.name,
      customerId: response.id,
      email: response.email,
      userName: response.email,
      password: "@HasaDocumentos01",
      phone: response.phone,
      level: 5,
    };
    UserInsert(
      user,
      (res) => {
        // SendUserInvitation(user);
        toast.success("Salvo com sucesso");
        onHide();
      },
      (error) => {
        toast.error(
          "Ocorreu um erro ao salvar o usuário, acesse o perfil desse cliente e tente novamente",
        );
        onHide();
      },
    );
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      onSave={() => update()}
      loading={loading}
      onSubmit={() => update()}
      disableSave={!Object.values(formValidation).every((x) => x === true)}
      title="Editar Cliente"
    >
      <div className="row">
        <div className="col-sm-12">
          {customer && customer.id === undefined ? (
            <div className="form-group">
              <label className="d-block">Tipo de cliente</label>
              <div className="custom-control custom-radio custom-control-inline custom-control-primary">
                <input
                  type="radio"
                  className="custom-control-input"
                  onClick={() =>
                    handleChange({ target: { value: 1, name: "type" } })
                  }
                  id="example-radio-custom-inline1"
                  checked={customer.type === 1 ? true : false}
                  name="example-radio-custom-inline"
                />
                <label
                  className="custom-control-label"
                  for="example-radio-custom-inline1"
                >
                  Pessoa física
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline custom-control-primary">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="example-radio-custom-inline2"
                  checked={customer.type === 2 ? true : false}
                  onClick={() =>
                    handleChange({ target: { value: 2, name: "type" } })
                  }
                  name="example-radio-custom-inline"
                />
                <label
                  className="custom-control-label"
                  for="example-radio-custom-inline2"
                >
                  Pessoa Jurídica
                </label>
              </div>
            </div>
          ) : (
            ""
          )}
          {customer && customer.id !== undefined && userData.level !== 5 ? (
            <div className="form-group">
              <label for="name">Cód. de cliente</label>
              <input
                type="text"
                className="form-control"
                placeholder="Cód."
                name="customerCode"
                defaultValue={customer && customer.customerCode}
                onChange={(evt) => handleChange(evt)}
              />
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <label for="name">Nome</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nome"
              // disabled={
              //   (customer && customer.type === 2) || customer.id === undefined
              //     ? false
              //     : true
              // }
              name="name"
              defaultValue={customer && customer.name}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group">
            <label for="email">E-mail</label>
            <input
              placeholder="E-mail"
              className="form-control"
              type="email"
              name="email"
              disabled={userData && userData.level !== 5 ? false : true}
              defaultValue={customer && customer.email}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group">
            <label for="website">Website</label>
            <input
              placeholder="Website"
              className="form-control"
              type="text"
              name="website"
              defaultValue={customer && customer.website}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group">
            <label for="address">Logradouro</label>
            <input
              placeholder="Logradouro"
              className="form-control"
              type="text"
              name="street"
              value={customer && customer.street}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group">
            <label for="address">Número</label>
            <input
              placeholder="Número"
              className="form-control"
              type="text"
              name="number"
              value={customer && customer.number}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group">
            <label for="address">Bairro</label>
            <input
              placeholder="Bairro"
              className="form-control"
              type="text"
              name="neighborhood"
              value={customer && customer.neighborhood}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group">
            <label for="address">Complemento</label>
            <input
              placeholder="Complemento"
              className="form-control"
              type="text"
              name="complement"
              value={customer && customer.complement}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group">
            <label for="city">Cidade</label>
            <input
              placeholder="Cidade"
              className="form-control"
              type="text"
              name="city"
              defaultValue={customer && customer.city}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group">
            <label for="uf">Estado</label>
            <select
              name="uf"
              onChange={(evt) => handleChange(evt)}
              className="form-control"
            >
              <option value={null}>Selecionar</option>
              {stateList &&
                stateList.map((state) => (
                  <option
                    value={state.shortName}
                    selected={customer.uf === state.shortName}
                  >
                    {state.longName} ({state.shortName})
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <label for="zipcode">Cep</label>
            <input
              placeholder="CEP"
              className="form-control"
              type="text"
              name="zipcode"
              defaultValue={customer && customer.zipcode}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          {customer && customer.type === 2 ? (
            <div className="form-group">
              <label for="document">CNPJ</label>
              <InputMask
                mask="99.999.999/9999-99"
                maskChar=""
                type="text"
                className="form-control"
                disabled={customer.id !== undefined ? true : false}
                id="advanced-filter-document"
                name="cnpj"
                placeholder="99.999.999/9999-99"
                value={customer && customer.cnpj}
                onChange={(event) => {
                  handleChange({
                    target: {
                      name: "cnpj",
                      value: event.target.value.replace(/\D/g, ""),
                    },
                  });
                }}
              />
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <label for="document">CPF</label>
            <InputMask
              mask="999.999.999-99"
              maskChar=""
              type="text"
              className="form-control"
              id="advanced-filter-document"
              disabled={customer.id !== undefined ? true : false}
              name="cpf"
              placeholder="999.999.999-99"
              value={customer && customer.cpf}
              onChange={(event) => {
                handleChange({
                  target: {
                    name: "cpf",
                    value: event.target.value.replace(/\D/g, ""),
                  },
                });
              }}
            />
          </div>
          <div className="form-group">
            <label for="document">CRC / OAB</label>
            <input
              placeholder="CRC"
              className="form-control"
              type="text"
              defaultValue={customer && customer.crc}
              name="crc"
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          {customer.id !== undefined ? (
            <div className="form-group">
              <label for="document">Logo</label>
              <input
                className="form-control"
                type="file"
                name="document"
                onChange={(evt) => uplodaPicture(evt.currentTarget.files[0])}
              />
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <label for="phone">Telefone comercial</label>
            <InputMask
              mask="(99) 9999-99999"
              maskChar=""
              type="text"
              className="form-control"
              id="advanced-filter-document"
              name="phone"
              placeholder="(99) 99999-9999"
              value={customer && customer.phone}
              onChange={(event) => {
                handleChange({
                  target: {
                    name: "phone",
                    value: event.target.value.replace(/\D/g, ""),
                  },
                });
              }}
            />
          </div>
          <div className="form-group">
            <label for="phone">Whatsapp</label>
            <InputMask
              mask="(99) 99999-9999"
              maskChar=""
              type="text"
              className="form-control"
              id="advanced-filter-document"
              name="phone"
              placeholder="11 99999-9999"
              value={customer && customer.whatsapp}
              onChange={(event) => {
                handleChange({
                  target: {
                    name: "whatsapp",
                    value: event.target.value.replace(/\D/g, ""),
                  },
                });
              }}
            />
          </div>
          {userData && userData.level !== 5 ? (
            <div className="form-group">
              <label for="status">Status</label>
              <select
                name="status"
                onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
                className="form-control"
              >
                <option value={null}>Selecionar</option>
                {customerHelper.StatusList.map((sts) => (
                  <option
                    value={sts.value}
                    selected={customer.status === sts.value}
                  >
                    {sts.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}
          {userData && userData.level !== 5 ? (
            <div>
              <div className="form-group">
                <label for="pro">Hasa Pro</label>
                <select
                  name="pro"
                  onChange={(evt) => handleChange(evt, FieldTypeObj.Bool)}
                  className="form-control"
                >
                  <option value={null}>Selecionar</option>
                  <option
                    value={true}
                    selected={customer && customer.pro == true ? true : false}
                  >
                    Habilitado
                  </option>
                  <option
                    value={false}
                    selected={customer && customer.pro == false ? true : false}
                  >
                    Desabilitado
                  </option>
                </select>
              </div>

              {customer && customer.pro == true ?
                <div className="form-group">
                  <div className="block-header">
                    <div className="block-title">Selecione o plano</div>
                    <div className="block-tools">
                      <div class="form-group">
                        <label class="d-block">Periodicidade</label>
                        <div class="custom-control custom-radio custom-control-inline custom-control-lg">
                          <input type="radio" class="custom-control-input" id="example-rd-custom-inline-lg1" name="example-rd-custom-inline-lg"
                            onChange={evt => setCustomer({ ...customer, viewTimeType: 0 }) | notifyChange()} defaultChecked={customer.timeType === 0 ? true : false} />
                          <label class="custom-control-label" for="example-rd-custom-inline-lg1">Anual</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline custom-control-lg">
                          <input type="radio" class="custom-control-input" id="example-rd-custom-inline-lg2" name="example-rd-custom-inline-lg"
                            onChange={evt => setCustomer({ ...customer, viewTimeType: 1 }) | notifyChange()} defaultChecked={customer.timeType === 1 ? true : false} />
                          <label class="custom-control-label" for="example-rd-custom-inline-lg2">Trimestral</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline custom-control-lg">
                          <input type="radio" class="custom-control-input" id="example-rd-custom-inline-lg3" name="example-rd-custom-inline-lg"
                            onChange={evt => setCustomer({ ...customer, viewTimeType: 2 }) | notifyChange()} defaultChecked={customer.timeType === 2 ? true : false} />
                          <label class="custom-control-label" for="example-rd-custom-inline-lg3">Mensal</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {change && plans && plans.map(item => (
                      <div className="col-md-4">
                        <div class="block block-rounded block-bordered block-themed text-center">
                          <div class="block-header bg-muted">
                            <h3 class="block-title">{item.name}</h3>
                          </div>
                          <div class="block-content bg-body-light">
                            <div class="py-2">
                              <p class="h1 font-w700 mb-2">R${customer.viewTimeType == 0 ? MoneyMask(item.priceYerly, 2) : customer.viewTimeType == 1 ? MoneyMask(item.priceQuartely, 2) : MoneyMask(item.price, 2)}</p>
                              <p class="h6 text-muted"></p>
                            </div>
                          </div>
                          <div class="block-content">
                            <div class="py-2">
                              {item.items.map(option => (
                                <p>
                                  <strong>{option.number}</strong> {option.name}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div class="block-content block-content-full bg-body-light">
                            {customer.planId == item.id && customer.viewTimeType == customer.timeType ?
                              <button class="btn btn-hero-success px-4" disabled={true}>
                                <i class="fa fa-check mr-1"></i> Plano atual
                              </button>
                              :
                              <button class="btn btn-hero-secondary px-4" onClick={() => activatePlan(item, customer.viewTimeType)}>
                                <i class="fa fa-check mr-1"></i> Ativar Plano
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                : ""}
              {customer && customer.pro == true ?
                <div className="form-group">
                  <span>Data de expiração</span>
                  <input type="date" className="form-control" name="expirationDate" value={customer && OnlyDateInputTranslate(customer.expirationDate)} onChange={evt => handleChange(evt)} />
                </div>
                : ""}
              {change && customer && customer.pro == true ?
                <div>
                  <div className="form-group">
                    <span>Frequencia da consulta de Regularidade:</span>
                    <select name="frequencyType" className="form-control" onChange={evt => handleChange(evt, FieldTypeObj.Number)}>
                      <option value={CustomerHelper().FrequencyType.Monthly}>Mensal</option>
                      <option value={CustomerHelper().FrequencyType.Custom}>Customizado</option>
                    </select>
                  </div>
                </div>
                : ""}

            </div>
          ) : (
            ""
          )}
          {customer && customer.pro == true ?
            <div>

              {change && customer && customer.frequencyType === CustomerHelper().FrequencyType.Monthly ? (
                <div className="form-group">
                  <span>Consultar todo dia:</span>
                  <input type="number" max={30} min={1} name="frequency" value={customer && customer.frequency} className="form-control" onChange={evt => handleChange(evt)} />
                </div>
              )
                :
                (<div className="form-group">
                  <span>Consultar a cada:</span>
                  <select name="frequency" className="form-control" value={customer && customer.frequency} onChange={evt => handleChange(evt)}>
                    <option value={7}>7 dias</option>
                    <option value={15}>15 dias</option>
                  </select>
                </div>
                )}
            </div>
            : ""}


          {userData && userData.level !== 5 ? (
            <div className="form-group">
              <label for="observation">Observações</label>
              <textarea
                name="observation"
                onChange={(evt) => handleChange(evt)}
                value={customer.observation}
                className="form-control"
              />
            </div>
          ) : (
            ""
          )}

          {userData && userData.level !== 5 ? (
            <div className="form-group">
              <label for="level">Nível</label>
              <select
                name="level"
                onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
                className="form-control"
              >
                <option value={null}>Selecionar</option>
                {customerHelper.CustomerLevelList.map((lvl) => (
                  <option
                    value={lvl.value}
                    selected={customer.level === lvl.value}
                  >
                    {lvl.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}
          {userData && userData.level !== 5 && customer.id != undefined ? (
            <FollowingCustomerCard customerId={customer && customer.id} />
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
}

const CustomerEdit = CustomerModalComponent;

export default CustomerEdit;
