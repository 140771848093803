import { ReminderController } from "@/app/core/base/controllers/ReminderController";
import Storage from "@/app/core/util/Storage";
import { OrganHelper } from "@/app/core/util/helpers/OrganHelper";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";
import React, { useEffect, useState } from "react";

export default function ReminderCard({ customerId }) {
    const [data, setData] = useState([]);
    const userData = Storage.getUserData();

    useEffect(()=>{
        loadReminders()
    },[])

    function loadReminders() {
        ReminderController.List(customerId, res => {
            console.log("LEmbrete", res);
            setData(res.filter(x => x.status == ReminderHelper.Status.Ongoing));
        })
    }


    return (
        <div
            className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1"
            style={{ height: 210 }}
            onClick={() => (window.location = `#/reminders`)}
        >
            <div className="block-header">
                <div className="block-title">
                    <i className="si si-calendar fa-2x"></i>
                </div>
                <div className="block-tools">
                    <i
                        className="si si-question"
                        title="Tenha acesso a nossa ferramenta incrível para facilitar a cobrança de documentos e valores dos seus clientes"
                    />
                </div>
            </div>
            <div className="block-content">
                <h3 className="mb-1">Agenda de lembretes</h3>{data && data.length} lembretes ativos
            </div>
        </div>
    );
}
