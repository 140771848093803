import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";

export default function TaskTutorialModal({ show, onHide }) {
    const [loading] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <CustomModal
            title="Tutorial"
            show={show}
            isLoading={loading}
            onHide={onHide}>
                <strong>O que é a Lista de Tarefas?</strong>
            <p>
                A Lista de Tarefas é uma ferramenta do Hasa Pro desenvolvida para ajudar você a gerenciar suas atividades diárias de maneira simples e eficiente. Com ela, você pode manter o foco e otimizar seu tempo.
            </p>
            
            <strong>Benefícios da Lista de Tarefas:</strong>
            <ul>
                <li>Organização: Centralize todas as suas tarefas em um único lugar, facilitando a gestão do seu dia a dia.</li>
                <li>Prioridades: Classifique suas tarefas por urgência e importância, garantindo que você sempre trabalhe nas atividades mais relevantes.</li>
                <li>Produtividade: Aumente sua eficiência com lembretes personalizáveis e prazos que ajudam a manter o foco.</li>
                <li>Acessibilidade: Acesse suas listas de qualquer dispositivo, a qualquer hora, garantindo que você nunca perca uma tarefa.</li>
            </ul>
            
            <strong>Funcionalidades:</strong>
            <ul>
                <li>Criação de Tarefas: Adicione, edite e exclua tarefas de forma rápida e intuitiva</li>
                <li>Notificações: Receba lembretes automáticos para não perder prazos importantes e manter-se no caminho certo.</li>
            </ul>
            
            <strong>Como Funciona:</strong>
            <ul>
                <li>Acesse a Lista de Tarefas: Vá até o Menu Principal do Hasa Pro e clique em "Lista de Tarefas".</li>
                <li>Adicione suas Tarefas: Clique em "Adicionar Tarefa" e descreva a atividade que precisa realizar.</li>
                <li>Defina Prazos e Prioridades: Estabeleça prazos e classifique a tarefa conforme sua urgência e importância.</li>
                <li>Acompanhe seu Progresso: À medida que conclui tarefas, marque-as como feitas e visualize suas conquistas no histórico de atividades.</li>
            </ul>
            <div className="text-center py-3">
                Experimente a Lista de Tarefas e otimize sua rotina!
                <br />

                Estamos aqui para esclarecer suas dúvidas!

            </div>
        </CustomModal>
    );
}
