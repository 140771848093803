import OptionalServiceController from "@/app/core/base/controllers/OptionalServiceController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import OptionalOrganListComponent from "./OptonalOrganListComponent";
import { ServiceGroupType } from "@/app/core/util/helpers/SelectedServiceHelper";
import CurrencyFormat from "react-currency-format";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import DiscountListComponent from "./DiscountListComponent";
import { ProcessPrice } from "@/app/core/util/helpers/BudgetHelper";
import StepComponent from "./StepComponent";

export default function OptionalServiceItem({
  refresh,
  service,
  disabled,
  radialChange,
  clientView,
  isClient,
  group,
}) {
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(Storage.getUserData());

  const optionalService = OptionalServiceController();

  useEffect(() => {
    if (service && service.id !== undefined) {
      setData(service);
    } else {
      setData({});
    }
  }, [service]);

  async function saveOrUpdate() {
    if (edit === true) {
      if (
        data.priceEdited < data.price &&
        userData != null &&
        userData.level === 5
      ) {
        toast.error(Message.LowPrice);
        return;
      }
      save(data);
    }
    setEdit(!edit);
  }

  function selectMultiple() {
    let infos = data;
    infos.selected = !data.selected;

    save(infos);
  }

  function handleRadial(evt) {
    let infos = data;
    infos.selected =
      evt.target.checked === true || evt.target.checked === "true"
        ? true
        : false;

    radialChange(infos);
    // save(infos);
  }

  function recomend() {
    let oldData = data;
    oldData.recomended = !data.recomended;
    setData(oldData);
    save(oldData);
  }

  function save(infos) {
    optionalService.Update(infos, (res) => {
      toast.success("Salvo com sucesso");
      refresh();
    });
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function deleteOptionalService() {
    if (window.confirm(Message.DeleteConfirm)) {
      await optionalService.Delete(data.id, (res) => {
        toast.success(Message.DeleteSuccess);
        setTimeout(() => {
          refresh();
        }, 450);
      });
    }
  }

  async function loadCurrentService() {
    await optionalService.GetById(data.id, (res) => setData(res));
  }

  return (
    <div
      className={`block block-rounded border ${
        data.selected === true ? "border-success" : ""
      } border-2x mb-2 ribbon ribbon-right ribbon-bookmark ribbon-warning ribbon-bottom`}
      key={data.id}
    >
      {data &&
      data.recomended === true &&
      (userData === null || userData.level === 5) ? (
        <div className="ribbon-box" style={{ marginRight: -15 }}>
          <small>Recomendado</small>
        </div>
      ) : (
        ""
      )}
      <div className="row pr-2 pl-2 pt-2 pb-2">
        <div className="col-5 text-left">
          {edit === false && (userData === null || userData.level === 5)
            ? `${data && data.serviceNameEdited}`
            : ""}
          {edit === false && userData !== null && userData.level !== 5
            ? `${data && data.serviceName}`
            : ""}
          {edit === true && userData.level === 5 && clientView !== true ? (
            <input
              className="form-control"
              defaultValue={data && data.serviceNameEdited}
              name="serviceNameEdited"
              onChange={(evt) => handleChange(evt)}
            />
          ) : (
            ""
          )}
          {edit === true && userData.level !== 5 ? (
            <input
              className="form-control"
              defaultValue={data && data.serviceName}
              name="serviceName"
              onChange={(evt) => handleChange(evt)}
            />
          ) : (
            ""
          )}
          {userData !== null && clientView !== true ? (
            <button
              className="btn btn-sm btn-dual"
              disabled={disabled}
              onClick={() => saveOrUpdate()}
            >
              {edit === true ? (
                <span>
                  <i className="si si-check text-success" /> Salvar
                </span>
              ) : (
                <span>
                  <i className="fa fa-edit text-info" /> Editar
                </span>
              )}
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="col-7 row text-right">
          {userData && userData.level !== 5 ? (
            <div className="col-10 text-right">
              {data && data.recomended === true ? (
                <button
                  className="btn btn-sm btn-square btn-warning ml-1"
                  disabled={disabled}
                  onClick={() => recomend()}
                >
                  <i className={`far fa-window-close`}></i> Remover
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-square btn-success ml-1"
                  disabled={disabled}
                  onClick={() => recomend()}
                >
                  <i className={`far fa-check-square`}></i> Indicar
                </button>
              )}

              <button
                className="btn btn-sm btn-square btn-dual ml-1"
                disabled={disabled}
                onClick={() => deleteOptionalService()}
              >
                <i className={`si si-close text-danger`}></i>
              </button>
            </div>
          ) : (
            ""
          )}
          {/* </div> */}
          {/* {userData === null || userData.level === 5 ? ( */}
          <div
            className={
              userData === null || userData.level === 5
                ? "col-12 text-right"
                : "col-2 text-right"
            }
          >
            {group && group.type === ServiceGroupType.SingleChoice ? (
              <div className="custom-control custom-radio custom-control-primary mb-1">
                <input
                  type="radio"
                  className="custom-control-input"
                  id={data && `service-${data.id}-${clientView}`}
                  name={data && `group-${data.serviceGroupId}-${clientView}`}
                  onChange={(evt) => handleRadial(evt)}
                  defaultChecked={data && data.selected}
                  disabled={disabled}
                />
                <label
                  className="custom-control-label"
                  for={data && `service-${data.id}-${clientView}`}
                >
                  {userData === null || userData.level === 5
                    ? "Selecionar"
                    : ""}
                </label>
              </div>
            ) : (
              ""
            )}
            {group && group.type === ServiceGroupType.MultipleChoise ? (
              <div className="custom-control custom-switch custom-control-inline custom-control-success">
                <input
                  type="checkbox"
                  className="custom-control-input mt-1"
                  name={data && `service-${data.id}`}
                  checked={data && data.selected}
                  disabled={disabled}
                />
                {userData === null || userData.level === 5 ? (
                  <label
                    className="custom-control-label"
                    onClick={(evt) => {
                      if (disabled != true) {
                        selectMultiple();
                      } else {
                      }
                    }}
                    for={data && `service-${data.id}`}
                  >
                    {data && data.selected === true ? "Remover" : "Adicionar"}
                  </label>
                ) : (
                  <label
                    className="custom-control-label"
                    onClick={(evt) => {
                      if (disabled != true) {
                        selectMultiple();
                      }
                    }}
                    for={data && `service-${data.id}`}
                  ></label>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* ) : (
          ""
        )} */}
        <div className="col-12">
          {edit === false ? (
            <p>
              {data && (userData === null || userData.level === 5)
                ? data.descriptionEdited
                : data.description}
            </p>
          ) : (
            ""
          )}
          {edit === true && userData.level === 5 ? (
            <textarea
              className="form-control"
              value={data && data.descriptionEdited}
              name="descriptionEdited"
              onChange={(evt) => handleChange(evt)}
            />
          ) : (
            ""
          )}
          {edit === true && userData.level !== 5 ? (
            <textarea
              className="form-control"
              value={data && data.description}
              placeholder="Descrição"
              name="description"
              onChange={(evt) => handleChange(evt)}
            />
          ) : (
            ""
          )}
          {data && data.editable == true ? (
            <div className="row">
              <div className="form-group col-6">
                {edit !== true ? (
                  ""
                ) : (
                  <input
                    className="form-control"
                    value={data && data.price}
                    type="number"
                    placeholder="Valor"
                    name="price"
                    onChange={(evt) => handleChange(evt, FieldTypeObj.Currency)}
                  />
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={
            userData !== null && userData.level !== 5
              ? "col-6 text-left"
              : "col-12 text-left"
          }
        >
          {edit === true && userData.level === 5 ? (
            <div className="form-group">
              <label for="example-masked-time">Valor R$</label>
              <CurrencyFormat
                value={data.priceEdited}
                className={`form-control`}
                thousandSeparator={"."}
                decimalSeparator={","}
                name="marketValue"
                prefix={"R$"}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  handleChange(
                    { target: { name: "priceEdited", value: value } },
                    FieldTypeObj.Currency,
                  );
                }}
              />
            </div>
          ) : (
            ""
          )}
          {edit === false &&
          (userData === null || userData.level === 5) &&
          clientView === true &&
          isClient === false ? (
            <div>{data && ProcessPrice(data)}</div>
          ) : (
            ""
          )}

          {edit === false &&
          (userData === null || userData.level === 5) &&
          clientView === false &&
          isClient === false ? (
            <div>R${MoneyMask(data.priceEdited, 2)}</div>
          ) : (
            ""
          )}
          {edit === false &&
          (userData === null || userData.level === 5) &&
          clientView === true &&
          isClient === true ? (
            <p className="text-success">R${MoneyMask(data.priceEdited, 2)}</p>
          ) : (
            ""
          )}
          {edit === false && userData !== null && userData.level !== 5 ? (
            <p className="text-success">R${MoneyMask(data.price, 2)}</p>
          ) : (
            ""
          )}
        </div>
        <div className="col-6 text-right">
          {userData !== null && userData.level !== 5 ? (
            <button
              className="btn btn-sm btn-square btn-dual ml-1"
              onClick={() => setOpen(!open)}
            >
              <i
                className={`si si-arrow-${open === true ? "up" : "down"} mr-1`}
              ></i>
              {open === true ? "Esconder" : "Ver órgãos"}
            </button>
          ) : (
            ""
          )}
        </div>
        {userData && userData.level !== 5 ? (
          <div className="col-12">
            <DiscountListComponent
              service={data}
              refresh={() => loadCurrentService()}
              optionalServiceId={data && data.id}
              disabled={disabled}
            />
          </div>
        ) : (
          ""
        )}
        {/* {userData &&
        userData.level !== 5 &&
        data &&
        data.steps &&
        data.steps.length > 0 ? (
          <div className="col-12">
            <StepComponent
              service={data}
              refresh={() => loadCurrentService()}
              optionalServiceId={data && data.id}
              disabled={disabled}
            />
          </div>
        ) : (
          ""
        )} */}
        {open === true ? (
          <div className="col-12">
            <OptionalOrganListComponent
              disabled={disabled}
              service={data}
              organs={data && data.optionalOrganList}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
