import Table from "@/app/core/components/table";
import {
  DateTranslate,
  OnlyDateTranslate,
} from "@/app/core/util/DateTranslater";
import Functions from "@/app/core/util/Functions";
import { Autocomplete } from "@/app/core/util/helpers/AutocompleteHelper";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { downloadExcel, DownloadTableExcel } from "react-export-table-to-excel";

export default function CustomerListCard({
  title,
  infos,
  cols,
  reload,
  hasFilter,
}) {
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState([]);
  const [stages, setStages] = useState([]);
  const tableRef = useRef(null);
  const header = ["Nome", "Codigo", "Email", "Telefone"];

  useEffect(() => {
    if (infos !== null && infos !== undefined) {
      let itens = [];
      for (let s in infos) {
        let item = infos[s];
        item.condition = "";
        item.updatedDate = DateTranslate(item.updatedDate);
        item.createdDate = DateTranslate(item.createdDate);
        itens.push(item);
      }
      setData(itens);
      setRaw(itens);
    } else {
      setData([]);
      setRaw([]);
    }

    let stageList = CustomerHelper().StageNameList;
    for (let s of stageList) {
      s.active = true;
    }

    setStages(stageList);
  }, [infos]);

  useEffect(() => {
    search();
  }, [question]);

  function search() {
    if (question.length == 0) {
      setData(raw);
    } else {
      const results = autocomplete();
      setData(results);
    }
  }

  function autocomplete() {
    let newResults = [];
    const itens = raw;
    const value = question;
    for (let s in itens) {
      const item = itens[s];

      const customerCode = item.customerCode?.toLowerCase();
      const name = item.name?.toLowerCase();
      const email = item.email?.toLowerCase();
      const phone = item.phone?.toLowerCase();
      const whatsapp = item.whatsapp?.toLowerCase();
      const lastTimeline = item?.lastTimelineString?.toLowerCase();

      if (
        (customerCode && customerCode.includes(value.toLowerCase())) ||
        (name && name.includes(value.toLowerCase())) ||
        (email && email.includes(value.toLowerCase())) ||
        (phone && phone.includes(value.toLowerCase())) ||
        (lastTimeline && lastTimeline.includes(value.toLowerCase())) ||
        (whatsapp && whatsapp.includes(value.toLowerCase()))
      ) {
        newResults.push(item);
      }
    }
    return newResults;
  }

  function handleCheckeds(item, index) {
    let old = stages;
    old[index].active = item.target.checked;
    console.log("Chegou", old);

    var customers = [];

    for (let s in old) {
      const item = old[s];
      if (item.active) {
        const result = raw.filter((x) => x.stage == item.value);
        console.log("Chegou 2", old, result);
        if (result.length > 0) {
          customers = [...customers, ...result];
        }
      }
    }

    console.log("Chegou 3", customers);
    setStages(old);
    setData(customers);
  }

  function handleDownloadExcel() {
    let old = [];
    for (let s of data) {
      old.push({
        Nome: s.name,
        Codigo: s.customerCode,
        Email: s.email,
        Telefone: s.whatsapp ? s.whatsapp : s.phone,
      });
    }
    console.log("dados", old, header);
    downloadExcel({
      fileName: "Relatorio",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: old,
      },
    });
  }

  return (
    <div className="block block-rounded">
      <div className="block-header">
        <div className="block-title">{title}</div>
        <div className="block-tools">
          {reload && (
            <button className="btn btn-sm btn-light" onClick={() => reload()}>
              <i className={`si si-refresh`}></i>
            </button>
          )}
          <button
            className="btn btn-sm btn-light"
            onClick={() => setOpen(!open)}
          >
            <i className={`si si-arrow-${open === true ? "up" : "down"}`}></i>
          </button>
        </div>
      </div>
      {open == true ? (
        <div className="block-content">
          <div className="form-group">
            <div className="input-group">
              <input
                type="text"
                placeholder={`Pesquisar...`}
                className="form-control form-control-alt"
                value={question}
                onChange={(evt) => setQuestion(evt.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text input-group-text-alt">
                  <i className="fa fa-search"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex">
            {hasFilter == true ? (
              <Dropdown>
                <Dropdown.Toggle variant="light">Filtrar</Dropdown.Toggle>
                <Dropdown.Menu>
                  {stages &&
                    stages.map((item, index) => (
                      <div className="custom-control custom-checkbox mb-1">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={item.name}
                          name={item.name}
                          checked={item.active}
                          onChange={(evt) => handleCheckeds(evt, index)}
                        />
                        <label className="custom-control-label" for={item.name}>
                          {item.name}
                        </label>
                      </div>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
            {hasFilter == true ? (
              <button className="btn btn-info" onClick={handleDownloadExcel}>
                <i className="fa fa-download" /> Exportar Excel{" "}
              </button>
            ) : (
              ""
            )}
          </div>
          <Table
            title="Clientes"
            loading={false}
            data={data}
            columns={cols}
            tableId={`table-customer-${hasFilter}`}
            hide={false}
            limit={100}
            hideHeader={true}
            setFilter={(filtered) => Functions.ProcessFilter(filtered, data)}
            viewAction={
              (selected) =>
                window.open(`#/customer-profile/${selected.data.id}`, "_blank")
            }
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
