
import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { ElaboratorHelper } from "@/app/core/util/helpers/ElaboratorHelper";

export default function NewContractModal({ show, onHide }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
    }, []);

    function handleChange(evt, type){
        setData(OnDataChange(data, evt, type));
    }

    function handleFormType(item){
        let oldData = data;
        oldData.type = item;

        setData(oldData);
        window.open(`#/drafter/${item}`, "_blank");

        setTimeout(()=>{
            onHide()
        },500)
    }

    return (
        <CustomModal
            title="Novo contrato"
            show={show}
            isLoading={loading}
            onHide={onHide}>
            <div className="form-group">
                <span>Nome da empresa:</span>
                <input className="form-control" value={data && data.name} onChange={evt => handleChange(evt)}/>
            </div>
            <div className="row">
                {ElaboratorHelper.TypeList.map(item => (
                    <div className="col-md-4">
                        <button className={`btn btn-block btn-${item.color}`} onClick={()=> handleFormType(item.value)}>
                            {item.name}
                        </button>
                    </div>
                ))}
            </div>
        </CustomModal>
    );
}
