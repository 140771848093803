import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const PlanController = {
  Get: async function (planId, response) {
    await BaseService(
      {
        method: "GET",
        url: `plan/${planId}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  
  List: async function (response) {
    await BaseService(
      {
        method: "GET",
        url: `plan/`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },

  Insert: async function (data, response) {
    BaseService(
      {
        method: "POST",
        url: "plan/insert",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },

  InsertItem: async function (data, response) {
    BaseService(
      {
        method: "POST",
        url: "plan/item/insert",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },

  Update: async function (data, response) {
    BaseService(
      {
        method: "POST",
        url: "plan/update",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },

  UpdateItem: async function (data, response) {
    BaseService(
      {
        method: "POST",
        url: "plan/item/update",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },

  Delete: async function (id, response) {
    await BaseService(
      {
        method: "DELETE",
        url: `plan/delete/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },

  DeleteItem: async function (id, response) {
    await BaseService(
      {
        method: "DELETE",
        url: `plan/item/delete/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },


}
