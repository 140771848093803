import React, { useEffect, useState } from "react";
import BudgetListModal from "./BudgetListModal";
import CustomerNewBudget from "@/app/core/layouts/main-components/budget/CustomerNewBudget";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";

export default function BudgetCard({ customerId }) {
  const [showModal, setShowModal] = useState(false);
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [budgets, setBudgets] = useState([]);

  useEffect(()=>{
    loadBudgets()
  },[])
  
  function loadBudgets(){
    ProcessController().CustomerBudgets(customerId, (res) => {
      setBudgets(
        res.filter((x) => x.budgetStatus == BudgetStatus.WaitingApproval),
      );
    });
  }

  function openItem() {
    if (budgets.length != 0) {
      setShowModal(true);
    } else {
      setShowBudgetModal(true);
    }
  }

  return (
    <div>
      <div
        className="block block-fx-pop bg-gd-sun block-roundedMax mb-3 pb-3 ml-1 cursor"
        style={{ height: 210 }}
        onClick={() => openItem()}
      >
        <div className="block-header">
          <div className="block-title">
            <i className="far fa-chart-bar text-white fa-2x"></i>
          </div>
          <div className="block-tools">
            <i
              className="si si-question text-white"
              title="Acesse todos os orçamentos em Aberto com um clique!"
            />
          </div>
        </div>
        <div className="block-content text-white">
          {budgets && budgets.length == 0 ? (
            <div>
              <h4 className="text-white mt-0">
                Você não tem orçamentos em aberto – Solicite serviços agora
                mesmo
              </h4>
            </div>
          ) : (
            <div>
              <h3 className="text-white mt-3">
                {budgets && budgets.length}{" "}
                {budgets && budgets.length == 1 ? "Orçamento" : "Orçamentos"}
              </h3>
              Aguardando Resposta
            </div>
          )}
        </div>
      </div>
      <BudgetListModal
        process={budgets}
        show={showModal}
        customerId={customerId}
        reload={() => loadBudgets()}
        onHide={() => setShowModal(false) | loadBudgets()}
      />

      <CustomerNewBudget
        show={showBudgetModal}
        onHide={() => setShowBudgetModal(false)}
      />
    </div>
  );
}
