import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import Storage from "@/app/core/util/Storage";
import { OrganHelper } from "@/app/core/util/helpers/OrganHelper";
import React, { useEffect, useState } from "react";

export default function CompaniesCard({ customerId }) {
  const userData = Storage.getUserData();
  const [companies, setCompanies] = useState([])

  useEffect(()=>{
    loadCompanies()
  },[])
  
  function loadCompanies(){
    if(customerId){
      CompanyController.List(customerId, (res) => setCompanies(res));
    }
  }

  function irregular() {
    let res = 0;
    if (companies != undefined) {
      for (let s in companies) {
        const item = companies[s];
        const irregular = item.organs.filter(
          (x) => x.status == OrganHelper.CompanyOrganStatus.Irregular,
        );
        if (irregular.length > 0) {
          res++;
        }
      }
    }
    return res;
  }

  function openCompanies() {
    if (userData && userData.level == 5) {
      window.location = `#/companies`;
    }
  }

  return (
    <div onClick={() => openCompanies()}>
      <div
        className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1"
        style={{ height: 210 }}
      >
        <div className="block-header">
          <div className="block-title">
            <i className="si si-users fa-2x"></i>
          </div>
          <div className="block-tools">
            <i
              className="si si-question"
              title="Com nossa parceria você garante a regularidade cadastral de todos os seus clientes"
            />
          </div>
        </div>
        <div className="block-content pt-0">
          <h3 className="mt-0 pb-0 mb-0">Regularidade Cadastral</h3>
          {companies && companies.length} Empresas cadastradas <br />
          {companies && irregular()} Empresas em situação irregular
        </div>
      </div>
    </div>
  );
}
