import { AdministratorTeams } from "@/app/core/base/controllers/TeamsController";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { DepartmentsListHelper } from "@/app/core/util/helpers/DepartmentHelper";
import React, { useEffect, useState } from "react";
import TeamLine from "./TeamLine";

export default function TeamsModal({ show, onHide, level }) {
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState([]);
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState(0);

  useEffect(() => {
    if (show) {
      setDepartment(level);
    }
  }, [level, show]);

  useEffect(() => {
    if (show) {
      loadTeams();
    }
  }, [department]);

  useEffect(() => {
        if (show) {
    processFilter();
        }
  }, [department, show]);

  function processFilter(prelist) {
    const itens = prelist || raw;
    let teams = itens.filter((x) => x.department === department);
    setData(teams);
  }

  async function loadTeams() {
    setLoading(true);
    await AdministratorTeams((res) => {
      setLoading(false);
      setRaw(res)
      processFilter(res)
  });
  }

  return (
    <Modal title="Equipes" show={show} onHide={onHide} isLoading={loading}>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <span>Departamento</span>
            <select
              className="form-control"
              onChange={(evt) => setDepartment(parseInt(evt.target.value))}
            >
              <option>Selecione</option>
              {DepartmentsListHelper.map((item) => (
                <option
                  value={item.value}
                  selected={item.value === department ? true : false}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12">
          {department && data && data.map((item) => <TeamLine team={item} />)}
        </div>
      </div>
    </Modal>
  );
}
