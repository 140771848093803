import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function OptionalServiceController() {
  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `optionalservice/insert`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `optionalservice/update`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SelectOne(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `optionalservice/select-one`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Get(serviceGroupId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `optionalservice/group/${serviceGroupId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function GetById(serviceId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `optionalservice/${serviceId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(optionalServiceId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `optionalservice/delete/${optionalServiceId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Get,
    GetById,
    Delete,
    Update,
    SelectOne,
  };
}
