import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";
import Axios from "axios";

export function Search(query, userId, response) {
  BaseService(
    {
      method: "GET",
      url: `global?query=${query}&userId=${userId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      console.error("Search", err);
      toast.error(HandleErrors(err));
    },
  );
}

export function Distribute(response) {
  BaseService(
    {
      method: "GET",
      url: `process/run`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ProcessDistribute(response) {
  BaseService(
    {
      method: "GET",
      url: `process/distribute-process`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function CustomerGeralReports(customerId, response) {
  BaseService(
    {
      method: "GET",
      url: `global/customer/reports/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}


export function AdjustCompanyData(data){
  let base = data?.data[0];
  let response = {};

  if(base){
    response.description  = base["atividade_economica"];
    response.capital  = base["normalizado_capital_social"];
    response.capitalExtended  = base["capital_social"];
    response.street  = base["endereco_logradouro"];
    response.neighborhood  = base["endereco_bairro"];
    response.city  = base["endereco_municipio"];
    response.number  = base["endereco_numero"];
    response.complement  = base["endereco_complemento"];
    response.fantasyName  = base["nome_fantasia"];
    response.name  = base["razao_social"];
    response.postalCode  = base["normalizado_endereco_cep"];
    response.postalCode  = base["normalizado_endereco_cep"];

    return response;

  }else{
    return {};
  }
}

export function GetAddress(cep, res) {
  Axios.get(`https://viacep.com.br/ws/${cep}/json`, {})
    .then(function (response) {
      let location = {};
      if (response.data.logradouro) {
        location.street = `${response.data.logradouro}`;
      }
      if (response.data.bairro) {
        location.neighborhood = `${response.data.bairro}`;
      }
      if (response.data.localidade) {
        location.city = `${response.data.localidade}`;
      }

      res({ ...location, zipcode: cep, postalCode:cep });
    })
    .catch(function (error) {
      if (error) {
      }
    });
}

export function GetCompanyByTaxId(cnpj, res, err) {
  Axios.post(`https://api.infosimples.com/api/v2/consultas/receita-federal/cnpj?cnpj=${cnpj}token=9nX0rAsA3ifZwFLbeutQnhclvq-x0JJbO76VjoWL`, {
    headers: {"Access-Control-Allow-Origin": "*"},
  })
    .then(function (response) {
      res(response);
    })
    .catch(function (error) {
      err(error)
      if (error) {
      }
    });
}
